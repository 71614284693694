<template>
    <div id="media-library" tabindex="-1" @keydown.65.stop="selectAll">
        <media-library-client-select
            v-if="!clientId"
            :client-id="clientId"
            display="card"
            @client-selected="selectClient"
        />
        <template v-else>
            <div v-if="clientHasGenerativeAiFeaturesEnabled" class="media-library__action-tabs">
                <span :class="{ active: currentLibraryTab === 'import' }" @click="changeLibrarySection('import')">
                    DAM Content
                </span>
                <span :class="{ active: currentLibraryTab === 'imagine' }" @click="changeLibrarySection('imagine')">
                    AI Content
                </span>
            </div>
            <div v-if="currentLibraryTab === 'import'" id="media-library__nav-wrapper">
                <div v-if="sandboxMode">
                    <Button @click="selectClient(null)">Select Client</Button>
                    <span class="media-library__nav-wrapper--client-name">{{ clientName }}</span>
                </div>
                <Breadcrumb id="media-library__breadcrumbs" separator=">">
                    <BreadcrumbItem class="home-icon">
                        <a
                            v-if="activeFolderName !== '' && activeFolderName !== $route.params.clientId"
                            @click="goToFolder(root)"
                        >
                            <Icon type="ios-home-outline"></Icon>
                        </a>
                        <span v-else><Icon type="ios-home-outline"></Icon></span>
                    </BreadcrumbItem>
                    <BreadcrumbItem v-for="(breadcrumb, index) in breadcrumbs" :key="`breadcrumb-${index}`">
                        <a v-if="index < breadcrumbs.length - 1" @click="goToFolder(breadcrumb.id)">
                            {{ breadcrumb.name }}
                        </a>
                        <span v-else>{{ breadcrumb.name }}</span>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div class="media-library__sort">
                    <sort-media-library v-model="sortBy"></sort-media-library>
                </div>
                <div id="media-library__change-view">
                    <span>{{ itemTotalText }}</span>
                    <a :disabled="showGridView" @click.prevent="setShowGridView({ show: true })">
                        <Icon type="ios-grid-outline"></Icon>
                    </a>
                    <a :disabled="!showGridView" @click.prevent="setShowGridView({ show: false })">
                        <Icon type="ios-list"></Icon>
                    </a>
                </div>
            </div>
            <div id="media-library__content-wrapper">
                <div v-if="canManageMediaItems" id="media-library__sidebar">
                    <div v-if="canManageMediaItems" id="media-library__upload-area">
                        <div v-if="currentLibraryTab === 'import'" class="media-library__upload-content">
                            <Button
                                class="media-library-modal-footer__button--sidebar"
                                data-testid="content-library-popup__create-folder-button"
                                @click="addNewFolder = true"
                            >
                                Create New Folder
                            </Button>
                            <Button
                                v-if="showAddAssetFromLinkButton"
                                class="media-library-modal-footer__button--sidebar"
                                data-testid="content-library-popup__add-asset-button"
                                @click="toggleAddAssetFromUrlModal"
                            >
                                Add Asset from Link
                            </Button>
                            <Upload
                                v-if="uploadConfig.action"
                                class="media-library__upload"
                                data-testid="content-library-popup__upload-area"
                                :show-upload-list="false"
                                :action="uploadConfig.action"
                                :data="uploadConfig.data"
                                :multiple="true"
                                type="drag"
                                :before-upload="beforeUpload"
                                :on-success="handleSuccess"
                                :on-progress="uploadProgress"
                            >
                                <div class="media-library__upload-info">
                                    <Icon
                                        type="ios-cloud-upload-outline"
                                        class="media-library__upload-info--icon"
                                    ></Icon>
                                    <div class="media-library__upload-info--text">
                                        Drop files here or
                                        <a>click to upload</a>
                                    </div>
                                </div>
                            </Upload>
                            <div v-else class="media-library__upload-area-placeholder" />
                        </div>
                        <div
                            v-if="clientHasGenerativeAiFeaturesEnabled && currentLibraryTab === 'imagine'"
                            class="media-library__imagine-content"
                        >
                            <div class="advanced-model-options__input">
                                <label>Prompt</label>
                                <Input
                                    v-model="prompt"
                                    type="textarea"
                                    placeholder="Describe a scene in as much detail as possible"
                                ></Input>
                            </div>

                            <div class="advanced-model-options__input advanced-model-options__input-actions">
                                <span class="link-like" @click="toggleGenerateImagesAdvanced">
                                    Show advanced options
                                    <Icon type="md-cog"></Icon>
                                </span>
                                <Button class="ivu-btn-primary" @click="onSearch">Generate</Button>
                            </div>
                            <!-- <component
                                :is="componentName"
                                :model="model"
                                :prompt="prompt"
                                :init-image="signedInitImage"
                                @updatedCustomInputs="onSearch"
                            /> -->
                        </div>
                    </div>
                    <transition name="slide">
                        <media-library-asset-tags
                            v-if="clientHasGenerativeAiFeaturesEnabled && isOneImageSelected"
                            :asset="selectedAssetsArray[0]"
                        />
                    </transition>
                </div>
                <div id="media-library__data" :class="{ 'full-width': !canManageMediaItems }">
                    <!-- <slot></slot> -->
                    <div v-if="currentLibraryTab === 'imagine'" class="media-library__imagine-results">
                        <template v-if="searches.length">
                            <Button class="media-library__imagine-results-clear" @click="clearGeneratedResults">
                                Clear results
                                <Icon type="md-trash"></Icon>
                            </Button>
                            <search-results
                                v-for="searchOptions in searches"
                                :key="searchOptions.searchId"
                                :search-options="searchOptions"
                                :hover-options="hoverOptions"
                                @editImage="onEditImage"
                            />
                        </template>
                        <div v-else>
                            <span class="media-library__imagine-no-results-title">
                                Use the "Generate" button to create AI content.
                            </span>
                            <empty-message></empty-message>
                        </div>
                    </div>
                    <div v-else>
                        <Row v-if="showGridView" class="media-library__data-row">
                            <drag-select-container selector-class="media-library__data-col" @change="setDragSelected">
                                <template>
                                    <Spin v-if="isLoading" fix />
                                    <div v-else-if="!mediaItems.length" class="media-library__data-row-empty">
                                        Folder Empty
                                    </div>
                                    <Col
                                        v-for="item in mediaItemsForDisplay"
                                        v-else
                                        :key="item.id"
                                        span="6"
                                        :data-asset="item"
                                        :class="{ selected: selectedAssets[item.id], 'media-library__data-col': true }"
                                        @mousedown.native.prevent.stop="selectAsset(item)"
                                        @dblclick.native.stop="doubleClickSelectAsset(item)"
                                    >
                                        <Card
                                            :bordered="true"
                                            :body-style="{ padding: 0, width: '100%' }"
                                            class="media-library__data-card"
                                        >
                                            <div
                                                v-if="
                                                    item.type === 'FILE' &&
                                                    item.info.contentType &&
                                                    item.info.contentType.match(/^image\//)
                                                "
                                                class="media-library__data-card-image"
                                                :style="{ 'background-image': `url(${item.url})` }"
                                            ></div>
                                            <video
                                                v-else-if="
                                                    item.type === 'FILE' &&
                                                    item.info.contentType &&
                                                    item.info.contentType.match(/^video\//)
                                                "
                                                width="100%"
                                                height="125"
                                                autoplay
                                                loop
                                                muted
                                            >
                                                <source :src="item.url" :type="item.info.contentType" />
                                            </video>
                                            <Icon
                                                v-else-if="item.type === 'FILE' && fileIcon(item.name) === 'unknown'"
                                                class="media-library__data-card-icon"
                                                type="file"
                                                size="large"
                                            ></Icon>
                                            <div
                                                v-else-if="item.type === 'FILE'"
                                                class="file-format-icon media-library__data-card-icon"
                                            >
                                                <span :class="`pretty-file-icon-${fileIcon(item.name)}`"></span>
                                            </div>
                                            <span v-else class="media-library__folder-preview-wrapper">
                                                <img
                                                    v-if="item.url"
                                                    class="folder-preview media-library__data-card-image"
                                                    :src="item.url"
                                                    :alt="item.name"
                                                />
                                                <Icon
                                                    v-else
                                                    class="folder-preview media-library__data-card-icon"
                                                    type="ios-folder-outline"
                                                    size="large"
                                                ></Icon>
                                            </span>
                                            <div class="media-library__data-card-name">{{ item.name }}</div>
                                            <Button
                                                v-if="canManageMediaItems"
                                                icon="ios-trash-outline"
                                                type="error"
                                                ghost
                                                class="media-library__data-card-delete-button"
                                                @click.stop="deleteAsset(item)"
                                            ></Button>
                                            <Button
                                                v-if="showCopyButton(item.type)"
                                                icon="ios-copy"
                                                ghost
                                                class="media-library__data-card-copy-button"
                                                @click.stop="copyAssetIdToClipboard(item)"
                                            ></Button>
                                        </Card>
                                    </Col>
                                </template>
                            </drag-select-container>
                        </Row>

                        <Table
                            v-else
                            :columns="columns"
                            :data="mediaItems"
                            no-data-text="Folder Empty"
                            :row-class-name="rowClassName"
                            class="assetsTable"
                            @on-row-click="selectAsset"
                            @on-row-dblclick="doubleClickSelectAsset"
                        ></Table>
                    </div>
                </div>
            </div>
            <portal to="media-library-modal-footer">
                <div class="media-library-modal-footer__content">
                    <div class="media-library-modal-footer__status">
                        <p>{{ selectedAssetsInfo }}</p>
                    </div>
                    <div>
                        <div v-if="isSelectingImage" class="media-library__auto-resize-switch-container">
                            <labelled-switch :value="useAutoResize" @change="toggleAutoResize">
                                Auto Resize
                            </labelled-switch>
                        </div>
                        <Button
                            data-testid="content-library-popup__close-button"
                            class="media-library-modal-footer__button"
                            @click="onClose"
                        >
                            Close
                        </Button>
                        <Button
                            v-if="fileSelectionIsEnabled"
                            class="media-library-modal-footer__button"
                            :disabled="!Object.keys(selectedAssets).length"
                            type="primary"
                            @click="addSelections"
                        >
                            {{ addSelectionText }}
                        </Button>
                    </div>
                </div>
            </portal>
            <Modal
                title="Add new folder"
                :value="addNewFolder"
                :loading="addFolderLoading"
                data-testid="add-new-folder-modal"
                @on-cancel="closeAddFolder"
                @on-ok="addFolder"
            >
                <Input
                    v-model="newFolderName"
                    data-testid="add-new-folder-modal__input"
                    placeholder="New folder name"
                    @keyup.enter.native="addFolder"
                ></Input>
            </Modal>
            <add-asset-from-url-modal
                v-if="addAssetFromUrlModalIsVisible"
                :folder-id="folderId"
                :media-items="mediaItems"
                :asset-url="assetUrl"
                @assetAdded="assetAddedFromUrl"
                @close="toggleAddAssetFromUrlModal"
            />
            <hox-modal v-if="generateImagesAdvancedVisible" @close="toggleGenerateImagesAdvanced">
                <template #header>{{ ImagineModelToLabel[model] }} advanced options</template>

                <component
                    :is="componentName"
                    :model="model"
                    :prompt="prompt"
                    :init-image="signedInitImage"
                    @updatedCustomInputs="toggleGenerateImagesAdvanced"
                />
            </hox-modal>
        </template>
    </div>
</template>

<script>
import prettyFileIcons from "pretty-file-icons";
import DragSelect from "vue-drag-select";
import { createNamespacedHelpers } from "vuex";

// eslint-disable-next-line import/no-extraneous-dependencies
import slugify from "shared-utils/slugify";
// eslint-disable-next-line import/no-extraneous-dependencies
import { CoverType } from "shared-utils/imageBoss";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ZIP_MIME_TYPE } from "shared-utils/constants";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    MEDIA_TYPES,
    IMAGE_PROCESSING_MIMES,
    IMAGE_PROCESSING_EXTS
} from "@hoxton/lambda/gql/MediaLibrary/modules/constants";

import { AuthGetters } from "@/store/modules/auth";
import bus from "@/bus";
import mediaItemsQuery from "@/apollo/queries/MediaItems.gql";
import createMediaFolderMutation from "@/apollo/mutations/CreateMediaFolder.gql";
import uploadMediaFilesMutation from "@/apollo/mutations/UploadMediaFiles.gql";
import uploadAndExtractZipToFolderMutation from "@/apollo/mutations/UploadAndExtractZipToFolder.gql";
import updateMediaFilesMutation from "@/apollo/mutations/UpdateMediaFiles.gql";
import removeMediaMutation from "@/apollo/mutations/RemoveMedia.gql";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";
import AddAssetFromUrlModal from "@/components/MediaLibraryAddAssetFromUrlModal";
import { EditableImageTypes } from "@/enums/editables";
import { MediaLibraryAction } from "@/store/modules/mediaLibrary";
import { bytesToSize, formatTimestamp, getObjectValue, splitFileNameAndExtension, uploadConfigExpired } from "@/utils";
import SortMediaLibrary from "@/components/SortMediaLibrary";
import copyToClipboard from "@/utils/copyToClipboard";
import MediaLibraryAssetTags from "@/components/MediaLibraryAssetTags";
import MediaLibraryClientSelect from "@/components/MediaLibraryClientSelect";
import { ImagineModel, ImagineModelToLabel, ModelDefaultValues } from "@/enums/imagine";
import AdvancedDalleOptions from "@/components/Imagine/AdvancedModelOptions/AdvancedDalleOptions";
import EmptyMessage from "@/components/common/EmptyMessage";
import SearchResults from "@/components/Imagine/SearchResults";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers("mediaLibrary");

export const MediaLibraryAssetEvent = {
    AssetRemoved: "AssetRemoved",
    AssetsUpdated: "AssetsUpdated"
};

export const MediaLibraryAssetType = {
    File: "FILE",
    Folder: "FOLDER"
};

export default {
    name: "MediaLibrary",

    components: {
        AddAssetFromUrlModal,
        LabelledSwitch,
        EmptyMessage,
        SortMediaLibrary,
        SearchResults,
        MediaLibraryAssetTags,
        MediaLibraryClientSelect,
        AdvancedDalleOptions,
        "drag-select-container": DragSelect
    },

    data() {
        return {
            addAssetFromUrlModalIsVisible: false,
            generateImagesAdvancedVisible: false,
            addFolderLoading: false,
            currentLibraryTab: "import",
            columns: [
                {
                    title: "Name",
                    key: "name",
                    className: "media-library__asset-name-column",
                    render: (h, params) => {
                        const elements = [
                            h("Icon", {
                                class: { "media-library__asset-icon": true },
                                props: {
                                    type:
                                        params.row.type.toLowerCase() === "file"
                                            ? "ios-document-outline"
                                            : "ios-folder-outline"
                                }
                            }),
                            h("span", params.row.name)
                        ];

                        if (this.canManageMediaItems) {
                            elements.push(
                                h("Button", {
                                    class: { "media-library__asset-delete-button": true },
                                    props: {
                                        icon: "ios-trash-outline",
                                        type: "error",
                                        ghost: true,
                                        size: "small"
                                    },
                                    on: {
                                        click: () => {
                                            this.deleteAsset(params.row);
                                        }
                                    }
                                })
                            );
                        }

                        return h("span", elements);
                    }
                },
                {
                    title: "Last modified",
                    key: "modified",
                    render: (h, params) => h("span", formatTimestamp(params.row.info.modified))
                },
                {
                    title: "Created by",
                    key: "createdBy",
                    render: (h, params) => h("span", params.row.info.createdBy)
                },
                {
                    title: "Created",
                    key: "created",
                    render: (h, params) => h("span", formatTimestamp(params.row.info.created))
                }
            ],
            assetUrl: "",
            mediaItems: [],
            mediaItemsForDisplay: [],
            model: ImagineModel.Dalle3,
            breadcrumbs: [],
            selectedAssets: {},
            root: null,
            addNewFolder: false,
            isLoading: false,
            processing: 0,
            loadPercent: 0,
            newFolderName: "",
            selectActiveFileOnMediaItemsLoad: true,
            uploadConfig: {},
            extractList: {},
            uploadList: {},
            updateList: {},
            prompt: "",
            query: "",
            searchCount: 0,
            searches: [],
            signedInitImage: "",
            hoverOptions: {
                newWindow: true,
                showArrows: false,
                showEdit: false,
                setAsBase: false,
                showAddToLibrary: true
            }
        };
    },

    computed: {
        ...mapState(["activeFile", "activeFolder", "showGridView", "activeInstance"]),

        ...mapGetters(["activeFolderName"]),

        canListMediaItems() {
            return this.$auth.userCan(this.$auth.Actions.CanListMediaItems);
        },

        canManageMediaItems() {
            if (this.sandboxMode) {
                return false;
            }
            return this.$auth.userCan(this.$auth.Actions.CanManageMediaItems);
        },

        clientHasGenerativeAiFeaturesEnabled() {
            if (this.$store.state.ui.currentClient) {
                return (
                    this.$store.state.ui.currentClient.imageProcessingEnabled &&
                    this.$store.getters[AuthGetters.isSuperAdmin]
                );
            }

            return false;
        },

        clientId() {
            if (this.sandboxMode) {
                return this.sandboxSelectedClient._id;
            }

            return this.clientIdFromRoute;
        },

        clientName() {
            if (this.sandboxMode) {
                return this.sandboxSelectedClient.name;
            }
            return "";
        },

        componentName() {
            switch (this.model) {
                default:
                    return "advanced-dalle-options";
            }
        },

        folderId() {
            return this.activeFolder || this.root;
        },

        sandboxMode() {
            return !this.clientIdFromRoute;
        },

        itemTotalText() {
            return `${this.mediaItems.length} item${this.mediaItems.length !== 1 ? "s" : ""}`;
        },

        isOneImageSelected() {
            const assets = Object.values(this.selectedAssets);
            if (assets.length !== 1) {
                return false;
            }
            const [selectedAsset] = assets;
            const { info: { contentType } = {} } = selectedAsset;
            if (contentType) {
                return IMAGE_PROCESSING_MIMES.some(mime => mime === contentType);
            }

            return IMAGE_PROCESSING_EXTS.some(ext => selectedAsset.persistentKey.endsWith(ext));
        },

        clientIdFromRoute() {
            return this.$route.params.clientId;
        },

        selectedAssetsInfo() {
            const selectedAssets = Object.keys(this.selectedAssets);

            if (!selectedAssets.length) {
                return "";
            }
            if (selectedAssets.length > 1) {
                return `${selectedAssets.length} assets selected`;
            }

            const info = [];

            if (this.selectedAssets[selectedAssets[0]].name) {
                info.push(`Name: ${this.selectedAssets[selectedAssets[0]].name}`);
            }

            return info.concat(this.selectedAssetInfo).join(" | ");
        },

        selectedAssetInfo() {
            const info = [];
            const selectedAssets = Object.keys(this.selectedAssets);

            if (this.selectedAssets[selectedAssets[0]].info.contentType) {
                info.push(`Kind: ${this.selectedAssets[selectedAssets[0]].info.contentType}`);
            }
            if (this.selectedAssets[selectedAssets[0]].info.contentLength) {
                info.push(`Size: ${bytesToSize(this.selectedAssets[selectedAssets[0]].info.contentLength)}`);
            }
            if (this.selectedAssets[selectedAssets[0]].info.createdBy) {
                info.push(`Created by: ${this.selectedAssets[selectedAssets[0]].info.createdBy}`);
            }
            if (this.selectedAssets[selectedAssets[0]].info.modified) {
                info.push(`Modified: ${formatTimestamp(this.selectedAssets[selectedAssets[0]].info.modified)}`);
            }
            return info.join(" | ");
        },

        selectedAssetTags() {
            const [selectedAsset] = this.selectedAssetsArray;
            if (!selectedAsset) {
                return null;
            }
            return selectedAsset.info.tags || null;
        },

        shouldShowAssetMetadata() {
            return this.selectedAssetsArray.length === 1;
        },

        selectedAssetsArray() {
            return Object.values(this.selectedAssets);
        },

        addSelectionText() {
            return `Add Selection${Object.keys(this.selectedAssets).length > 1 ? "s" : ""}`;
        },

        fileSelectionIsEnabled() {
            return this.activeInstance !== null && this.activeInstance !== undefined;
        },

        isSelectingImage() {
            return (
                this.$store.state.mediaLibrary.activeInstance &&
                EditableImageTypes.includes(this.$store.state.mediaLibrary.activeInstance.type)
            );
        },

        sandboxSelectedClient() {
            return this.$store.state.mediaLibrary.sandboxSelectedClient || {};
        },

        showAddAssetFromLinkButton() {
            return this.$store.state.mediaLibrary.activeInstance === null || this.isSelectingImage;
        },

        sortBy: {
            get() {
                return this.$store.state.mediaLibrary.sortBy;
            },
            set(sortBy) {
                this.$store.dispatch(MediaLibraryAction.SetSortBy, sortBy);
            }
        },

        useAutoResize: {
            get() {
                return this.$store.state.mediaLibrary.useAutoResize;
            },
            set(useAutoResize) {
                this.$store.dispatch(MediaLibraryAction.SetUseAutoResize, useAutoResize);
            }
        }
    },

    watch: {
        canListMediaItems() {
            if (!this.canListMediaItems) {
                this.$snackbar.warning("You do not have access to list media items");
                this.close();
            }
        },

        mediaItems(newVal, oldVal) {
            if (this.activeFile && this.selectActiveFileOnMediaItemsLoad) {
                const activeAsset = this.mediaItems.find(mediaItem => mediaItem.id === this.activeFile);
                if (activeAsset && !this.selectedAssets[activeAsset.id]) {
                    this.selectAsset(activeAsset);
                }
                this.selectActiveFileOnMediaItemsLoad = false;
            }
            if (newVal.length && oldVal.length) {
                const oldIds = oldVal.map(({ id }) => id);
                const newIds = newVal.map(({ id }) => id);
                if (newIds.every(id => oldIds.includes(id))) {
                    return;
                }
            }

            this.mediaItemsForDisplay = this.mediaItems;
        },

        $route() {
            // Close the media library when the route changes
            this.close();
        }
    },

    async created() {
        this.ImagineModelToLabel = ImagineModelToLabel;
        this.ImagineModel = ImagineModel;
        // make sure we have up to date data - fixes issues when switching between clients
        await this.$apollo.queries.mediaItems.refetch();
    },

    methods: {
        ...mapMutations(["setActiveFolder", "setShowGridView"]),

        ...mapActions(["close"]),

        deselectAsset(assetId) {
            this.$delete(this.selectedAssets, assetId);
        },

        selectAll(event) {
            if (getObjectValue(this, "activeInstance.multiple") && (event.ctrlKey || event.metaKey)) {
                const allSelectedAssets = {};
                this.mediaItems.forEach(asset => {
                    if (!this.disableSelectByType(asset)) {
                        allSelectedAssets[asset.id] = asset;
                    }
                });
                this.selectedAssets = allSelectedAssets;

                if (!this.addNewFolder) {
                    event.preventDefault();
                }
            }
        },

        setDragSelected(selectedAssets) {
            // Reset selected assets on drag resert
            this.selectedAssets = {};
            // Set selected assets
            selectedAssets.forEach(selectedAsset => {
                const asset = selectedAsset.$attrs["data-asset"];
                // Only select suited assets
                if (
                    this.activeInstance &&
                    !this.disableSelectByType(asset) &&
                    (this.activeInstance.multiple || selectedAssets.length === 1)
                ) {
                    this.$set(this.selectedAssets, asset.id, asset);
                }
            });
        },

        selectAsset(asset) {
            // Only allow selection of suitable assets
            if (this.disableSelectByType(asset)) {
                return;
            }
            // Update selected assets
            if (this.selectedAssets[asset.id]) {
                this.deselectAsset(asset.id);
            } else if (this.activeInstance && this.activeInstance.multiple) {
                this.$set(this.selectedAssets, asset.id, asset);
            } else {
                this.selectedAssets = { [asset.id]: asset };
            }
        },

        async selectClient(client) {
            // Only reset things when the client actually changes
            if (!client || client._id !== this.sandboxSelectedClient._id) {
                this.root = null;
                this.setActiveFolder({ folder: null });
            }
            this.$store.dispatch(MediaLibraryAction.SetSandboxSelectedClient, client);
            await this.$apollo.queries.mediaItems.refetch();
        },

        showCopyButton(itemType) {
            return itemType === MEDIA_TYPES.FILE;
        },

        disableSelectByType(asset) {
            if (!this.activeInstance) {
                return asset.type === MEDIA_TYPES.FOLDER;
            }

            return (
                (asset.type === MEDIA_TYPES.FOLDER && this.activeInstance.type !== "folder") ||
                (asset.type === MEDIA_TYPES.FILE && this.activeInstance.type === "folder")
            );
        },

        changeLibrarySection(section) {
            this.selectedAssets = {};
            this.clearGeneratedResults();
            this.currentLibraryTab = section;
        },

        doubleClickSelectAsset(asset) {
            if (asset.type === "FOLDER") {
                this.selectedAssets = {};
                this.setActiveFolder({ folder: asset.id });
            }
        },

        goToFolder(folderId) {
            this.selectedAssets = {};
            this.setActiveFolder({ folder: folderId });
        },

        async addFolder() {
            if (this.addFolderLoading) {
                return;
            }
            if (this.newFolderName.trim() === "") {
                this.$snackbar.fieldRequired("Folder name must not be empty");
                return;
            }
            // Lock mutation to avoid duplicate folder add
            this.addFolderLoading = true;
            try {
                await this.$apollo.mutate({
                    mutation: createMediaFolderMutation,
                    variables: {
                        clientId: this.clientId,
                        folderId: this.folderId,
                        newFolderName: this.newFolderName
                    },
                    refetchQueries: [
                        {
                            query: mediaItemsQuery,
                            variables: {
                                clientId: this.clientId,
                                folderId: this.folderId,
                                orderBy: this.sortBy
                            }
                        }
                    ]
                });
                this.closeAddFolder();
            } catch (err) {
                this.$snackbar.error(err.message);
            } finally {
                this.addFolderLoading = false;
            }
        },

        closeAddFolder() {
            this.newFolderName = "";
            this.addNewFolder = false;
        },

        processConfig(config) {
            this.uploadConfig = { ...config };
            this.uploadConfig.data = JSON.parse(this.uploadConfig.postData);
            this.uploadConfig.data.key = this.uploadConfig.filePrefix;
        },

        async handleSuccess(response, file, list) {
            const allFilesUploaded = list.filter(listFile => listFile.status !== "uploading").length === list.length;
            // Only process if all files have uploaded
            if (allFilesUploaded) {
                // Get extract list
                const extractList = Object.keys(this.extractList);
                // Process zip files to be extracted
                if (extractList.length) {
                    // we want to handle the promises one at the time.
                    await extractList.reduce(
                        (p, fileName) =>
                            p.then(async () => {
                                await this.getZipPrompt(fileName);
                            }),
                        Promise.resolve()
                    );
                }

                this.processUploads();
            }
        },

        async getZipPrompt(fileName) {
            return new Promise(resolve => {
                this.$Modal.confirm({
                    title: "Extract Zip?",
                    content: `Would you like to extract the contents of ${fileName} on upload.`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                        // in case of multiple zips we need to allow time for modal to hide before showing it with new values
                        setTimeout(() => {
                            resolve();
                        }, 500);
                    },
                    onCancel: () => {
                        // Zip not being extracted so add to upload or update list
                        if (this.extractList[fileName].fileId) {
                            this.updateList[fileName] = this.extractList[fileName];
                        } else {
                            this.uploadList[fileName] = this.extractList[fileName].tempPath;
                        }
                        // Extract not required so remove from like
                        delete this.extractList[fileName];
                        // Process on last zip
                        setTimeout(() => {
                            resolve();
                        }, 500);
                    }
                });
            });
        },

        beforeUpload(file) {
            // Valid file is less than 10MB
            const isLt500M = file.size / 1024 / 1024 < 500;
            // If not less than 100MB then notify user and reject upload
            if (!isLt500M) {
                this.$snackbar.image({ message: `Uploading ${file.name} failed. File size can not exceed 100MB.` });
                return false;
            }

            // Check the upload config is still valid
            if (uploadConfigExpired(this.uploadConfig)) {
                this.$snackbar.error("There was an issue uploading a file. Please try again.");
                return this.$apollo.queries.mediaItems.refetch();
            }

            const fileNameAndExtension = splitFileNameAndExtension(file.name);
            const sanitisedFileName = `${slugify(fileNameAndExtension.name)}${fileNameAndExtension.extension}`;
            // Set key and check if file exists
            this.uploadConfig.data.key += `/${sanitisedFileName}`;
            const currentFile = this.mediaItems.find(item => item.type === "FILE" && item.name === sanitisedFileName);
            // Make sure a file with the same name is updated not added
            if (file.type === ZIP_MIME_TYPE) {
                this.extractList[sanitisedFileName] = {
                    tempPath: this.uploadConfig.data.key,
                    fileId: currentFile ? currentFile.id : null
                };
            } else if (currentFile) {
                this.updateList[sanitisedFileName] = {
                    tempPath: this.uploadConfig.data.key,
                    fileId: currentFile.id
                };
            } else {
                this.uploadList[sanitisedFileName] = this.uploadConfig.data.key;
            }
            // Return true to proceed with upload
            return true;
        },

        /* we need this wrapper callback to prevent vue from passing event object as a first param */
        onClose() {
            this.close();
        },

        uploadError(e) {
            this.$snackbar.error("Update Error", e);
        },

        uploadProgress(e) {
            bus.$emit("percentLoadEvent", e.percent);
        },

        processUploads() {
            // Get extract, upload and update lists
            const extractList = Object.keys(this.extractList);
            const uploadList = Object.keys(this.uploadList);
            const updateList = Object.keys(this.updateList);
            // Add files if upload list is not empty
            if (uploadList.length) {
                this.addFiles(uploadList);
                this.processing += 1;
            }
            // Update files if update list is not empty
            if (updateList.length) {
                this.updateFiles(updateList);
                this.processing += 1;
            }
            if (extractList.length) {
                this.extractFiles(extractList);
                this.processing += extractList.length;
            }
            // Set loader if processing
            if (this.processing) {
                this.loadPercent = 50;
                bus.$emit("percentLoadEvent", this.loadPercent);
            }
        },

        addFiles(fileList) {
            // Build files list
            const files = fileList.map(fileName => ({
                name: fileName.replace(/\.[^/.]+$/, ""),
                temporaryPath: this.uploadList[fileName]
            }));
            // Fire files mutation
            this.$apollo
                .mutate({
                    mutation: uploadMediaFilesMutation,
                    variables: {
                        clientId: this.clientId,
                        files,
                        folderId: this.folderId
                    },
                    refetchQueries: [
                        {
                            query: mediaItemsQuery,
                            variables: {
                                clientId: this.clientId,
                                folderId: this.folderId,
                                orderBy: this.sortBy
                            }
                        }
                    ]
                })
                .then(() => {
                    const plural = fileList.length > 1 ? "s" : "";
                    this.$snackbar.success(
                        `File${plural} uploaded`,
                        `${fileList.length} file${plural} successfully added!`
                    );
                })
                .catch(err => {
                    this.$snackbar.error(err.message);
                })
                .finally(() => {
                    this.uploadList = {};
                    this.updateProcessingStatus();
                });
        },

        updateFiles(fileList) {
            // Build files list
            const files = fileList.map(fileName => ({
                name: fileName.replace(/\.[^/.]+$/, ""),
                fileId: this.updateList[fileName].fileId,
                temporaryPath: this.updateList[fileName].tempPath
            }));
            // Fire files mutation
            this.$apollo
                .mutate({
                    mutation: updateMediaFilesMutation,
                    variables: {
                        clientId: this.clientId,
                        folderId: this.folderId,
                        files
                    },
                    refetchQueries: [
                        {
                            query: mediaItemsQuery,
                            variables: {
                                clientId: this.clientId,
                                folderId: this.folderId,
                                orderBy: this.sortBy
                            }
                        }
                    ]
                })
                .then(() => {
                    const plural = fileList.length > 1 ? "s" : "";
                    this.$snackbar.success(
                        `File${plural} updated`,
                        `${fileList.length} file${plural} successfully updated!`
                    );
                    bus.$emit(MediaLibraryAssetEvent.AssetsUpdated, fileList);
                })
                .catch(err => {
                    this.$snackbar.error(err.message);
                })
                .finally(() => {
                    this.updateList = {};
                    this.updateProcessingStatus();
                });
        },

        extractFiles(fileList) {
            fileList.forEach(fileName => {
                this.$apollo
                    .mutate({
                        mutation: uploadAndExtractZipToFolderMutation,
                        variables: {
                            clientId: this.clientId,
                            folderId: this.folderId,
                            tmpPath: this.extractList[fileName].tempPath
                        },
                        refetchQueries: [
                            {
                                query: mediaItemsQuery,
                                variables: {
                                    clientId: this.clientId,
                                    folderId: this.folderId,
                                    orderBy: this.sortBy
                                }
                            }
                        ]
                    })
                    .then(() => {
                        this.$snackbar.success("Zip extracted", `${fileName} successfully extracted!`);
                    })
                    .catch(err => {
                        this.$snackbar.error(err.message);
                    })
                    .finally(() => {
                        this.extractList = {};
                        this.updateProcessingStatus();
                    });
            });
        },

        updateProcessingStatus() {
            this.loadPercent += Math.ceil(50 / this.processing);
            this.loadPercent = this.loadPercent > 100 ? 100 : this.loadPercent;
            bus.$emit("percentLoadEvent", this.loadPercent);
            // Reset values if 100% loaded
            if (this.loadPercent === 100) {
                this.processing = 0;
                this.loadPercent = 0;
            }
        },

        copyAssetIdToClipboard(asset) {
            try {
                const assetString = `:hoxton/${asset.id}/${asset.name}`;
                copyToClipboard([assetString]);
                this.$snackbar.success("Asset reference has been copied to clipboard");
            } catch (e) {
                this.$snackbar.error("There was an error when trying to copy the reference, please try again.");
            }
        },

        deleteAsset(asset) {
            this.$Modal.confirm({
                title: "Are you sure?",
                content: `Deleting ${asset.name} will affect any campaign using it.`,
                okText: "Delete",
                onOk: async () => {
                    try {
                        await this.$apollo.mutate({
                            mutation: removeMediaMutation,
                            variables: {
                                clientId: this.clientId,
                                mediaIds: [asset.id]
                            },
                            refetchQueries: [
                                {
                                    query: mediaItemsQuery,
                                    variables: {
                                        clientId: this.clientId,
                                        folderId: this.folderId,
                                        orderBy: this.sortBy
                                    }
                                }
                            ]
                        });
                        // Now that the image is deleted we need to deselect it
                        this.deselectAsset(asset.id);
                        this.$snackbar.success(
                            `${asset.type.charAt(0)}${asset.type.substr(1).toLowerCase()} deleted`,
                            `${asset.name} successfuly deleted!`
                        );

                        bus.$emit(MediaLibraryAssetEvent.AssetRemoved, asset);
                        this.selectActiveFileOnMediaItemsLoad = true;
                        this.$apollo.queries.mediaItems.refetch();
                    } catch (err) {
                        this.$snackbar.unauthorized(err);
                    }
                }
            });
        },

        addSelections() {
            if (this.useAutoResize) {
                const selectedAssetsWithResizeSettings = Object.keys(this.selectedAssets).reduce((acc, assetId) => {
                    acc[assetId] = {
                        resizeSettings: {
                            cover: CoverType.Smart
                        },
                        ...this.selectedAssets[assetId]
                    };
                    return acc;
                }, {});
                this.close(selectedAssetsWithResizeSettings);
                return;
            }
            this.close(this.selectedAssets);
        },

        fileIcon(fileName) {
            return prettyFileIcons.getIcon(fileName);
        },

        rowClassName(row) {
            return row.id in this.selectedAssets ? "selected" : "";
        },

        toggleAddAssetFromUrlModal() {
            this.addAssetFromUrlModalIsVisible = !this.addAssetFromUrlModalIsVisible;
        },

        toggleGenerateImagesAdvanced() {
            this.generateImagesAdvancedVisible = !this.generateImagesAdvancedVisible;
        },

        assetAddedFromUrl(fileId) {
            this.toggleAddAssetFromUrlModal();
            this.$store.dispatch(MediaLibraryAction.SetActiveFile, fileId);
            this.selectActiveFileOnMediaItemsLoad = true;
            this.$apollo.queries.mediaItems.refetch();
        },

        toggleAutoResize() {
            this.useAutoResize = !this.useAutoResize;
        },

        setModel(model) {
            this.model = model;
            this.$emit("input", this.model);
        },

        customInputs() {
            if (!this.$store.state.imagine.useAdvancedOptions) {
                return {};
            }

            return this.$store.state.imagine.advancedOptions[this.model];
        },

        clearGeneratedResults() {
            this.searches = [];
        },

        onSearch() {
            this.searchCount += 1;
            // todo mak filling in the correct fields more intelligent
            let { prompt } = this;
            if (this.model === ImagineModel.PlayHT) {
                prompt = [this.prompt];
            }
            const newSearch = {
                searchId: this.searchCount,
                ...ModelDefaultValues[this.model],
                prompt,
                model: this.model,
                ...(this.signedInitImage && {
                    init_image: this.signedInitImage
                }),
                ...this.customInputs
            };

            this.searches.unshift(newSearch);
            this.balance -= this.costPerCall;
        },
        onEditImage({ url }) {
            this.assetUrl = url;
            this.toggleAddAssetFromUrlModal();
        }
    },

    apollo: {
        mediaItems: {
            query: mediaItemsQuery,

            skip() {
                return !this.clientId;
            },

            // fetchPolicy: "no-cache",

            variables() {
                return {
                    clientId: this.clientId,
                    folderId: this.folderId,
                    orderBy: this.sortBy
                };
            },

            watchLoading(isLoading) {
                this.isLoading = isLoading;
            },

            result({ data, loading }) {
                // todo: review and fix the bug: https://github.com/Akryum/vue-apollo/issues/492
                // todo: HOX-964
                if (loading) {
                    return;
                }

                if (!data.mediaItems) {
                    this.mediaItems = [];
                } else {
                    this.mediaItemsForDisplay = data.mediaItems;
                }

                if (data.folderBreadCrumbs) {
                    const breadcrumbs = [...data.folderBreadCrumbs];
                    breadcrumbs.shift();
                    this.breadcrumbs = breadcrumbs;
                    this.root = data.folderBreadCrumbs[0].id;
                } else {
                    this.root = null;
                    this.breadcrumbs = [];
                }

                if (data.client) {
                    this.processConfig(data.client.mastheadUploadConfig);
                }
            },

            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        }
    }
};
</script>

<style scoped>
.media-library__upload {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#media-library {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    outline: 0;
}

#media-library__nav-wrapper {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

#media-library__breadcrumbs,
#media-library__change-view {
    font-size: 14px;
}

#media-library__change-view a {
    display: inline-block;
    margin-left: 6px;
    font-size: 18px;
}

#media-library__content-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
}

#media-library__sidebar {
    width: 25%;
    height: 100%;
    margin-right: 15px;
    position: relative;
}

#media-library__upload-area {
    display: flex;
    flex-direction: column;
    width: 99%;
    height: 100%;
    overflow: hidden;
}

.media-library__upload-area-placeholder {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    height: 100%;
}

#media-library__data {
    width: 75%;
    overflow: hidden scroll;
}

#media-library__data.full-width {
    width: 100%;
}

.media-library__upload {
    height: 100%;
}

.media-library__sort {
    margin-left: auto;
    margin-right: 20px;
}

#media-library >>> .media-library__asset-icon {
    margin-right: 8px;
}

.media-library__asset-icon {
    margin-right: 8px;
}

.media-library__data-card {
    cursor: pointer;
}

.media-library__data-card-icon,
.media-library__data-card-image {
    width: 100%;
    height: 125px;
}

.media-library__data-card-icon {
    font-size: 64px;
    padding: 34px 0;
}

.media-library__data-card-image {
    background: #f6f6f6 no-repeat center center;
    background-size: contain;
}

.media-library__data-card-name {
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
    font-size: 12px;
    border-top: 1px solid #e9e9e9;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    min-height: 40px;
}

.media-library__data-card-delete-button {
    position: absolute;
    z-index: 10;
    margin: 3px;
    bottom: 0;
    opacity: 0;
    font-size: 20px;
    padding: 0 5px;
}

.media-library__data-card-copy-button {
    position: absolute;
    z-index: 10;
    margin: 3px;
    bottom: 0;
    right: 0;
    opacity: 0;
    font-size: 20px;
    padding: 0 5px;
}

.media-library__data-card-delete-button .ivu-icon-ios-trash-outline {
    font-size: 20px;
}

#media-library >>> .media-library__asset-delete-button {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -17px;
    opacity: 0;
}

#media-library >>> .ivu-table-cell {
    position: relative;
    padding: 4px 18px;
}

#media-library >>> .ivu-table-row:hover .media-library__asset-delete-button,
#media-library >>> .ivu-table-row:hover .media-library__data-card-copy-button,
.media-library__data-card:hover .media-library__data-card-delete-button,
.media-library__data-card:hover .media-library__data-card-copy-button,
.media-library__data-card:hover .media-library__data-card-delete-button:hover,
.media-library__data-card:hover .media-library__data-card-copy-button:hover {
    opacity: 1;
    background-color: #ffffff;
    transition: none;
}

.media-library__data-card:hover .media-library__data-card-copy-button {
    color: #0092ff;
    border-color: #0092ff;
}

.media-library-modal-footer__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.media-library-modal-footer__status {
    flex: 1 0 0;
    padding: 5px 0;
    text-align: left;
}

.media-library__folder-preview-wrapper {
    display: block;
    height: 125px;
    text-align: center;
}

.folder-preview.media-library__data-card-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 125px;
    margin: auto;
    background: white;
}

.file-format-icon {
    padding: 25px 0;
    display: block;
}

.vue-drag-select {
    margin: 0 -6px;
    min-height: 100%;
    overflow: hidden;
}
</style>

<style lang="scss">
@import "../../sass/variables";

$icons: "7z" "aac" "ai" "archive" "arj" "audio" "avi" "css" "csv" "dbf" "doc" "dwg" "exe" "fla" "flac" "gif" "html"
    "iso" "jpg" "js" "json" "mdf" "mp2" "mp3" "mp4" "mxf" "nrg" "pdf" "png" "ppt" "psd" "rar" "rtf" "svg" "text" "tiff"
    "txt" "unknown" "video" "wav" "wma" "xls" "xml" "zip";

@each $icon in $icons {
    .pretty-file-icon-#{$icon} {
        background: url("~pretty-file-icons-svg/#{$icon}.svg") no-repeat center;
        display: block;
        height: 100%;
        width: 100%;
    }
}

#media-library {
    &__data.full-width {
        .media-library__data-col {
            width: 16.66%;
        }
    }

    .media-library {
        &__nav-wrapper {
            &--client-name {
                font-weight: bold;
                margin: 0 $spacing-base;
            }
        }

        &__upload {
            .ivu-upload-drag {
                flex: 1;
                height: 100%;
                width: 100%;
                display: flex;
            }

            &-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;

                &--text {
                    font-size: 14px;
                    padding: $spacing-smaller;
                }

                &--icon {
                    font-size: 64px;
                }
            }
        }

        &__asset-name-column {
            line-height: 47px;

            .media-library__asset-delete-button {
                font-size: $ios-trash-outline-fs;
                margin-top: -$ios-trash-outline-fs;
            }
        }

        &__data-row {
            padding: 0 4px 0;
            margin: 0 !important;

            .vue-drag-select {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
            }
        }

        &__data-row-empty {
            display: flex;
            justify-content: space-around;
            height: 100%;
            align-items: center;
            padding-left: $spacing-small;
        }

        &__data-col {
            padding: 0 $spacing-small $spacing-small;
            width: 25%;
            margin: 0 0 $spacing-small;

            &.selected .ivu-card-bordered {
                border-color: #108ee9;
            }

            &:nth-child(1n) {
                padding-left: 2px;
            }

            &:nth-child(4n) {
                padding-right: 2px;
            }
        }

        &__asset-tag-list {
            &.slide-enter-active,
            &.slide-leave-active {
                transition: left 0.5s;
            }

            &.slide-enter,
            &.slide-leave-to {
                left: -100%;
            }
        }
    }

    .media-library {
        &__action-tabs {
            border-bottom: 1px solid #5e00b5;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            span {
                display: inline-block;
                text-transform: capitalize;
                padding: 8px 15px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                margin: 0 5px;
                border: none;
                color: #5e00b5;
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
                &.active {
                    background: #5e00b5;
                    color: #fff;
                }
            }
        }
        &__upload-content {
            min-height: 40vh;
            .ivu-btn {
                width: 100%;
            }
        }
        &__imagine-content {
            label {
                display: block;
                width: 100%;
            }
            textarea {
                min-height: 40vh;
            }
        }
        &__imagine-model {
            font-size: 14px;
            border-radius: 8px;
            padding: 4px 10px;
            border: 1px solid rgb(139, 145, 154);
            color: #333;
            width: 150px;
            a {
                display: inline-block;
                width: 100%;
                .ivu-icon {
                    float: right;
                }
            }
        }
        &__imagine-results {
            background: #f8f9fb;
            border: 1px solid #e7eaee;
            box-shadow:
                0px 0px 1px 0px rgba(52, 58, 63, 0.1),
                0px 1px 5px 0px rgba(52, 58, 63, 0.1);
            border-radius: 12px;
            margin-bottom: 20px;
            padding: 16px 8px 0;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-clear {
                margin: 0 auto 10px 8px;
            }
            .search-results__header {
                padding: 0 8px;
            }
            .search-results-previews {
                display: flex;
            }
            .search-results-preview {
                margin: 8px;
            }
            .alert__content {
                h3 {
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
        &__imagine-no-results-title {
            display: inline-block;
            padding: 20px 0;
            text-align: center;
            width: 100%;
        }
    }

    &__breadcrumbs {
        .home-icon {
            font-size: 18px;
        }
    }

    &__change-view .ivu-icon {
        font-size: $ios-grid-outline-fs;
        font-weight: $ios-grid-outline-weight;
    }

    &__data {
        overflow: hidden auto;

        .ivu-card-body {
            padding: 0;

            video {
                display: block;
            }
        }
    }

    .assetsTable {
        tr.selected td {
            background-color: $table-td-hover-bg;
        }
    }
}

.media-library-modal-footer__button {
    margin: 0 0 0 $spacing-small;
}

.media-library-modal-footer__button--sidebar {
    margin: 0 0 $spacing-small;
}

.media-library__auto-resize-switch-container {
    display: inline-block;
    margin: 0 0 0 $spacing-small;
}
</style>
