/**
 * HoxtonError
 * Base Error Class to identify an error generated from the hoxton code base
 */
export default class HoxtonError extends Error {
    constructor(message, code) {
        super(message);
        // Ensure the name of this error is the same as the class name
        this.name = this.constructor.name;

        this.extensions = {
            type: this.name,
            code
        };

        // Make the message property one of our custom class' "own" properties
        // This ensures it is printed out when not used inide a GraphQLError
        Object.defineProperty(this, "message", { value: this.formatMessage(), enumerable: true });
    }

    /**
     * Override toString() so that we can include out codes and other custom fields in the logs
     *
     * @memberof HoxtonError
     */
    toString() {
        return this.message;
    }

    formatMessage() {
        let str = `${this.name}: ${this.message} (${this.extensions.code})`;
        const { type, code, ...props } = this.extensions;
        if (Object.keys(props).length) {
            str += JSON.stringify(props);
        }
        return str;
    }
}
