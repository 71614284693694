<template>
    <div class="intelligent-delivery__wrapper">
        <div class="dark-scrollbar intelligent-delivery__header">
            <section-nav />
            <header-actions-variants-selection
                v-if="isSelectVariantsVisible"
                :is-loading-deliverables="isLoadingDeliverables"
                :selected-variants-count="selectedVariantsCount"
                @saveVariantsSelection="onSaveVariantsSelection"
                @closeVariantsSelection="onCloseVariantsSelection"
                @selectAllFilters="onSelectAllFilters"
            ></header-actions-variants-selection>
            <header-actions-accounts
                v-else
                :show-save-changes="accountsList.length > 0"
                :show-save-reset-buttons="showSaveResetButtons"
                :is-loading="isLoading"
                @showPlatformAccountFormModal="addAccount"
                @cancelChanges="onCancelChanges"
                @saveChanges="onSaveChanges"
                @changeShowDeletedAccounts="onShowDeletedAccounts"
            ></header-actions-accounts>
        </div>
        <div class="intelligent-delivery__dashboard">
            <hox-loading-layer v-if="isLoading" :message="statusMessage" />

            <select-variants
                v-if="isSelectVariantsVisible"
                :key="selectedAccount + selectedRule"
                v-model="localSelectedFilters"
                @loadingDeliverables="onLoadingDeliverables"
                @selectedVariantsCount="onSelectVariantsCount"
            />
            <div v-else>
                <draggable
                    v-if="hasActiveAccounts()"
                    v-model="accountsList"
                    class="intelligent-delivery-accounts__draggable-wrapper"
                    handle=".intelligent-delivery__section-drag-handle"
                    @update="updateAccountsOrder"
                >
                    <div
                        v-for="(account, accountIndex) in visibleAccountsList"
                        :key="account.platformAccount.id"
                        :class="['intelligent-delivery__account', accountClasses(account)]"
                    >
                        <div class="intelligent-delivery__account-title">
                            <div class="intelligent-delivery__account-name">
                                <component :is="getAccountTypeIcon(account.platformAccount.platformDetails.type)" />
                                {{ account.platformAccount.name }}
                                <div
                                    v-if="account.platformAccount.publishStatus"
                                    class="intelligent-delivery__account-status"
                                >
                                    {{ PublishStatus[account.platformAccount.publishStatus] }}
                                    <extended-publish-status-details
                                        :platform-account="account.platformAccount"
                                        :campaign-id="campaignId"
                                    />
                                </div>
                            </div>
                            <div class="intelligent-delivery__account-actions">
                                <div v-if="account.platformAccount.status === AccountStatus.Deleted">
                                    <Tooltip placement="left" :transfer="true">
                                        <Button @click="restoreDeletedAccount(accountIndex)">
                                            <icon type="ios-undo" />
                                            Restore account
                                        </Button>
                                        <template #content>
                                            <template>
                                                Restore the account
                                                <br />
                                                and all it's rules.
                                            </template>
                                        </template>
                                    </Tooltip>
                                </div>
                                <div v-else>
                                    <Dropdown trigger="click" class="account__options">
                                        <Icon type="ios-settings" />
                                        <DropdownMenu slot="list">
                                            <DropdownItem @click.native="editAccount(accountIndex)">
                                                Edit
                                            </DropdownItem>
                                            <DropdownItem @click.native="deleteAccount(accountIndex)">
                                                Delete
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Icon
                                        :key="accountIndex"
                                        type="ios-move"
                                        class="intelligent-delivery__section-drag-handle"
                                    ></Icon>
                                </div>
                                <Button
                                    v-if="account.platformAccount.status !== RuleStatus.Deleted"
                                    class="intelligent-delivery__header-action-btn"
                                    data-testid="editor-header__add-new-rule-button"
                                    @click="addRule(accountIndex)"
                                >
                                    Add New Rule
                                </Button>
                            </div>
                        </div>
                        <draggable
                            v-if="hasActiveElements(account.rules)"
                            class="intelligent-delivery-rule__draggable-wrapper"
                            handle=".intelligent-delivery__rule-drag-handle"
                            @start="setEditedAccount(accountIndex)"
                            @update="updateRulesOrder"
                        >
                            <div
                                v-for="(rule, indexRule) in account.rules"
                                :key="rule.name + indexRule"
                                class="intelligent-delivery__account-rules"
                                :class="{
                                    'intelligent-delivery__rule-disabled':
                                        rule.status === RuleStatus.Deleted ||
                                        account.platformAccount.status === AccountStatus.Deleted
                                }"
                            >
                                <div class="intelligent-delivery__rule-header">
                                    <div class="intelligent-delivery__rule-header-content">
                                        <div v-if="rule.status" :class="getStatusClass(rule.status)">
                                            {{ rule.status }}
                                        </div>
                                        <div class="intelligent-delivery__rule-name">
                                            {{ rule.name }}
                                        </div>
                                    </div>

                                    <div
                                        v-if="rule.status === RuleStatus.Deleted"
                                        class="intelligent-delivery__signal-actions"
                                    >
                                        <Button @click.native="restoreRule(accountIndex, indexRule)">
                                            <icon type="ios-undo" />
                                            Restore rule
                                        </Button>
                                    </div>
                                    <div
                                        v-else-if="account.platformAccount.status !== AccountStatus.Deleted"
                                        class="intelligent-delivery__signal-actions"
                                    >
                                        <a @click="editRule(accountIndex, indexRule)">
                                            <icon type="md-create" />
                                            Edit rule
                                        </a>
                                        <a @click="duplicateRule(accountIndex, rule)">
                                            <icon type="md-copy" />
                                            Duplicate rule
                                        </a>
                                        <a @click="openDeleteRuleModal(accountIndex, indexRule)">
                                            <Icon type="md-trash" />
                                            Delete rule
                                        </a>
                                        <a class="intelligent-delivery__rule-drag-handle">
                                            <Icon type="ios-move" />
                                            Move rule
                                        </a>
                                    </div>
                                </div>
                                <div class="intelligent-delivery__account-variants">
                                    <div v-if="selectedNumberOfVariants(accountIndex, indexRule)">
                                        <p>
                                            <strong>
                                                You have
                                                {{ selectedNumberOfVariants(accountIndex, indexRule) }} variants
                                                selected.
                                            </strong>
                                        </p>
                                        <p>You can change or add to your current selection.</p>
                                    </div>
                                    <div v-else>
                                        <p>
                                            <strong>You don't have any variants selected.</strong>
                                        </p>
                                        <p>Please select a variant to create a signal.</p>
                                    </div>
                                    <a @click="toggleSelectVariants(accountIndex, indexRule)">Select variants</a>
                                </div>
                                <div
                                    v-if="selectedNumberOfVariants(accountIndex, indexRule)"
                                    class="intelligent-delivery__account-signals"
                                >
                                    <table v-if="rule.signals.length > 0">
                                        <tr class="intelligent-delivery__signal-header">
                                            <th class="intelligent-delivery__signal-name">Signal</th>
                                            <th class="intelligent-delivery__signal-status">Activity Status</th>
                                        </tr>
                                        <tr>
                                            <td
                                                class="intelligent-delivery__signal-container"
                                                @click="editSignal(accountIndex, indexRule)"
                                            >
                                                <p v-for="(signal, indexSignal) in rule.signals" :key="indexSignal">
                                                    <span
                                                        :ref="
                                                            'signalDescriptions' +
                                                            indexSignal +
                                                            indexRule +
                                                            accountIndex
                                                        "
                                                    ></span>
                                                </p>
                                            </td>
                                            <td>
                                                <span
                                                    class="intelligent-delivery__status"
                                                    :class="{
                                                        green: rule?.isRuleActiveBySignals
                                                    }"
                                                >
                                                    {{ getSignalStatus(rule) }}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>

                                    <div v-else class="intelligent-delivery__no-signals">
                                        <p>
                                            <strong>You don’t have any signals.</strong>
                                        </p>
                                        <p>Manage how your variants are delivered by creating custom signals.</p>
                                    </div>
                                </div>
                                <div
                                    v-if="selectedNumberOfVariants(accountIndex, indexRule)"
                                    class="intelligent-delivery__signal-add"
                                >
                                    <a @click="addSignal(accountIndex, indexRule)">
                                        <icon type="ios-add" class="intelligent-delivery__signal-add-icon" />
                                        Add new signal
                                    </a>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </draggable>
                <hox-empty-message v-else-if="!isLoading" :display-image-first="true" type="no-content-icon">
                    <template #title>
                        <strong>You don't have any signals</strong>
                    </template>
                    <template #content>
                        <p>
                            Add your platform to create new signals for managing the timing and delivery of your
                            campaigns.
                        </p>
                    </template>
                    <template #actionItems>
                        <Button data-testid="new-platform-account__button" type="primary" @click="addAccount">
                            + Add Meta Account
                        </Button>
                    </template>
                </hox-empty-message>
            </div>
            <platform-account-form-modal
                :is-visible="isAddAccountModalVisible"
                :account-index="selectedAccount"
                :form-data="formData"
                :account-action="accountAction"
                @close="toggleAddAccountModal"
                @addPlatformAccount="onAddPlatformAccount"
            />
            <account-rule-form-modal
                :is-visible="isAccountRuleModalVisible"
                :account-index="editedAccountIndex"
                :rule-index="editedRuleIndex"
                :form-data="formData"
                :rule-action="ruleAction"
                @close="toggleAccountRuleModal"
                @accountRuleAction="onAccountRuleAction"
            />
            <account-signal-form-modal
                :key="signalAction + addSignalCounter"
                :is-visible="isManageSignalsModalVisible"
                :account-index="editedAccountIndex"
                :rule-index="editedRuleIndex"
                :signal-index="editedSignalIndex"
                :form-data="formData"
                :signal-action="signalAction"
                @close="toggleAccountSignalModal"
                @accountSignalAction="onAccountSignalAction"
            />
            <alert-modal
                v-model="deleteRuleModal"
                header="Delete rule"
                title="Are you sure?"
                content="You can see and restore the deleted rule by making the deleted rules and accounts visible."
                ok-text="Delete Rule"
                :on-ok="deleteRule"
            />
        </div>
        <alert-modal
            v-model="isDiscardChangesModalVisible"
            header="Leaving the page"
            title="Do you really want to leave?"
            content="Unsaved changes will be lost. This cannot be undone."
            :warning="true"
            :on-ok="handleDiscardChanges"
        />
    </div>
</template>

<script>
import AlertModal from "@/components/Modal/Alert";
import Sidebar from "@/components/Sidebar";
import draggable from "vuedraggable";
import iconFacebook from "@/assets/intelligent-delivery/meta_social_media_icon.svg";
import iconTikTok from "@/assets/intelligent-delivery/tiktok_social media_icon.svg";
import iconGoogleDoubleClick from "@/assets/intelligent-delivery/google_icon.svg";
import NavigationTabs from "@/components/common/NavigationTabs/Container";
import NavigationTabsItem from "@/components/common/NavigationTabs/Tab";
import PlatformAccountFormModal from "@/components/IntelligentDelivery/PlatformAccountFormModal";
import AccountRuleFormModal from "@/components/IntelligentDelivery/AccountRuleFormModal";
import AccountSignalFormModal from "@/components/IntelligentDelivery/AccountSignalFormModal";
import PublishingDetailsModal from "@/components/IntelligentDelivery/PublishingDetailsModal";
import createCampaignFile from "@/apollo/mutations/CreateCampaignFile.gql";
import getIntelligentDeliveryApiInfoQuery from "@/apollo/queries/v2/GetIntelligentDeliveryApiInfo.gql";
import computedDeliverableComponentsQuery from "@/apollo/queries/v2/ComputedDeliverableComponents.gql";
import { fetchRequest } from "@/components/Reporting/data/utils";
import {
    AccountTypesList,
    AccountStatus,
    RuleStatus,
    PublishStatus,
    weekDays,
    timezones,
    SignalTypesList,
    PrecipitationAmountTypesList,
    TemperatureOperatorTypesList
} from "@/enums/activate";
import ExtendedPublishStatusDetails from "@/components/IntelligentDelivery/ExtendedPublishStatusDetails";
import { deepClone, formatGroupName, generateTemplateLabel } from "@/utils";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";
import { parseTemperatureValueString } from "@/utils/activate";
import { rrulestr } from "rrule";
import SelectVariants from "@/components/IntelligentDelivery/SelectVariants";
import SectionNav from "@/components/SectionNav";
import HeaderActionsVariantsSelection from "@/components/IntelligentDelivery/HeaderActionsVariantsSelection";
import HeaderActionsAccounts from "@/components/IntelligentDelivery/HeaderActionsAccounts";

export default {
    name: "IntelligentDelivery",
    components: {
        AlertModal,
        Sidebar,
        draggable,
        iconFacebook,
        iconTikTok,
        iconGoogleDoubleClick,
        NavigationTabs,
        NavigationTabsItem,
        PlatformAccountFormModal,
        ExtendedPublishStatusDetails,
        AccountRuleFormModal,
        AccountSignalFormModal,
        PublishingDetailsModal,
        SelectVariants,
        SectionNav,
        HeaderActionsAccounts,
        HeaderActionsVariantsSelection
    },
    props: {
        sandboxMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            AccountTypesList,
            AccountStatus,
            RuleStatus,
            PublishStatus,
            isLoading: true,
            isAddAccountModalVisible: false,
            isAccountRuleModalVisible: false,
            loadingLibraryFilterOptions: 0,
            isManageSignalsModalVisible: false,
            ruleEditTab: "general",
            statusMessage: "",
            formData: {},
            tempCampaignFileLocation: "",
            accountsList: [],
            latestVersionId: "",
            savedAccountsList: [],
            apiInfo: {},
            selectedAccount: 0,
            selectedRule: 0,
            editedAccountIndex: 0,
            editedRuleIndex: 0,
            editedSignalIndex: 0,
            accountAction: "add",
            ruleAction: "add",
            signalAction: "add",
            isSelectVariantsVisible: false,
            showDeletedAccounts: false,
            isPublishingStatusUpdating: false,
            statusInterval: null,
            isDetailedPublishingStatusUpdating: false,
            isDetailedPublishedStatusModalVisible: false,
            detailedPublishDetails: "",
            addSignalCounter: 0,
            nextFunction: () => {},
            isDiscardChangesModalVisible: false,
            weekDays,
            timezones,
            SignalTypesList,
            PrecipitationAmountTypesList,
            TemperatureOperatorTypesList,
            deleteRuleModal: false,
            deletedRuleIndex: 0,
            selectedVariantsCount: {
                selected: 0,
                total: 0
            },
            selectedFilters: {
                editableGroupValueIds: [],
                languages: [],
                masterTemplateIds: []
            },
            localSelectedFilters: {},
            isLoadingDeliverables: false
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        newAccountOrder() {
            let newAccountOrder = 1;
            if (this.accountsList.length) {
                const activeAccounts = this.accountsList.filter(
                    account => account.platformAccount.status === this.AccountStatus.Active
                );
                if (activeAccounts.length > 0) {
                    newAccountOrder =
                        activeAccounts.reduce((maxOrder, obj) => {
                            return obj.platformAccount.order > maxOrder ? obj.platformAccount.order : maxOrder;
                        }, activeAccounts[0].platformAccount.order) + 1;
                }
            }
            return newAccountOrder;
        },

        newRuleOrder() {
            let newRuleOrder = 1;
            const editedAccountRules = this.accountsList[this.editedAccountIndex].rules;
            if (editedAccountRules.length) {
                const activeAccounts = editedAccountRules.filter(rule => rule.status !== this.AccountStatus.Deleted);
                if (activeAccounts.length > 0) {
                    newRuleOrder =
                        activeAccounts.reduce((maxOrder, obj) => {
                            return obj.order > maxOrder ? obj.order : maxOrder;
                        }, activeAccounts[0].order) + 1;
                }
            }
            return newRuleOrder;
        },

        visibleAccountsList() {
            if (!this.showDeletedAccounts) {
                return this.accountsList
                    .map(item => {
                        const filteredRules = item.rules.filter(rule => rule.status !== this.RuleStatus.Deleted);
                        return {
                            ...item,
                            rules: filteredRules
                        };
                    })
                    .filter(item => item.platformAccount.status === this.AccountStatus.Active);
            }
            return this.accountsList;
        },
        hasInProgressStatus() {
            return this.accountsList.some(account => {
                const status = account.platformAccount?.publishStatus;
                return !status || ["IN_PROGRESS", "IN_PROGRESS_WITH_ERRORS"].includes(status);
            });
        },
        hasAccountChanges() {
            const keysToRemove = ["publishStatus", "publishSummary"];

            const acountWithoutPublishDetails = JSON.parse(JSON.stringify(this.accountsList))?.map(item => {
                return {
                    ...item,
                    platformAccount: Object.fromEntries(
                        Object.entries(item?.platformAccount).filter(([key]) => !keysToRemove.includes(key))
                    )
                };
            });

            return JSON.stringify(this.savedAccountsList) !== JSON.stringify(acountWithoutPublishDetails);
        }
    },

    watch: {
        accountsList: {
            immediate: true,
            deep: true,
            handler() {
                if (!this.hasAccountChanges) {
                    this.showSaveResetButtons = false;
                } else {
                    this.showSaveResetButtons = true;
                }
            }
        },

        selectedFilters: {
            immediate: true,
            deep: true,
            handler() {
                this.localSelectedFilters = this.selectedFilters;
            }
        }
    },

    beforeDestroy() {
        clearInterval(this.statusInterval);
    },

    updated() {
        if (!this.$store.state.ui.currentClient.connectModuleEnabled) {
            this.$router.push({ name: "CampaignEditor", params: { campaignId: this.campaignId } });
        }

        this.$nextTick(() => {
            if (!this.isSelectVariantsVisible) {
                this.accountsList.forEach((account, accountIndex) => {
                    account.rules.forEach((rule, ruleIndex) => {
                        rule.signals.forEach((signal, signalIndex) => {
                            this.setSignalDescription(
                                signal,
                                signalIndex,
                                ruleIndex,
                                accountIndex,
                                rule.signals.length
                            );
                        });
                    });
                });
            }
        });
    },

    methods: {
        accountClasses(account) {
            if (account.platformAccount.status === this.AccountStatus.Deleted) {
                return "intelligent-delivery__account-deleted";
            }
            return "";
        },

        addPublishStatus(accountPublishedStatus) {
            this.accountsList = this.accountsList.map(account => {
                const { id } = account.platformAccount;
                const matchedObj = accountPublishedStatus.data.find(item => item.accountId === id);
                return matchedObj
                    ? {
                          ...account,
                          platformAccount: {
                              ...account.platformAccount,
                              publishStatus: matchedObj.status,
                              publishSummary: matchedObj.summary
                          }
                      }
                    : account;
            });
        },

        getSignalIcon(type) {
            return `md-${type}`;
        },

        getAccountTypeIcon(accountType) {
            const account = Object.values(this.AccountTypesList).find(acc => acc.id === accountType);
            return account ? account.icon : null;
        },

        addAccount() {
            this.accountAction = "add";
            this.formData = {
                accountType: this.AccountTypesList.Facebook.id
            };
            this.toggleAddAccountModal();
        },

        addRule(index) {
            this.editedAccountIndex = index;
            this.ruleAction = "add";
            this.formData = {
                status: this.RuleStatus.Draft
            };
            this.toggleAccountRuleModal();
        },

        addSignal(accountIndex, ruleIndex) {
            this.addSignalCounter += 1;
            this.signalAction = "add";
            this.editedAccountIndex = accountIndex;
            this.editedRuleIndex = ruleIndex;
            this.formData = {
                signals: [{ signalType: "", value: "", locations: [] }]
            };
            this.toggleAccountSignalModal();
        },

        customSort(a, b) {
            if (
                a.platformAccount.status === this.AccountStatus.Active &&
                b.platformAccount.status === this.AccountStatus.Deleted
            ) {
                return -1;
            }
            if (
                a.platformAccount.status === this.AccountStatus.Deleted &&
                b.platformAccount.status === this.AccountStatus.Active
            ) {
                return 1;
            }
            return a.platformAccount.order - b.platformAccount.order;
        },

        ruleCustomSort(a, b) {
            if (a.status !== this.RuleStatus.Deleted && b.status === this.RuleStatus.Deleted) {
                return -1;
            }
            if (a.status === this.RuleStatus.Deleted && b.status !== this.RuleStatus.Deleted) {
                return 1;
            }
            return a.order - b.order;
        },

        toggleAccountRuleModal() {
            this.isAccountRuleModalVisible = !this.isAccountRuleModalVisible;
        },

        toggleAddAccountModal() {
            this.isAddAccountModalVisible = !this.isAddAccountModalVisible;
        },

        toggleAccountSignalModal() {
            this.isManageSignalsModalVisible = !this.isManageSignalsModalVisible;
        },

        toggleDiscardChangesModal() {
            this.isDiscardChangesModalVisible = !this.isDiscardChangesModalVisible;
        },

        handleDiscardChanges() {
            this.nextFunction();
        },

        onAddPlatformAccount(data) {
            // Some of values for the this object are hardcoded now. In the future they will be populated by the users.
            this.formData = data.formData;
            if (data.accountAction === "add") {
                let platformDetails = {};
                if (this.formData.accountType === this.AccountTypesList.Facebook.id) {
                    platformDetails = {
                        type: this.AccountTypesList.Facebook.id,
                        accountId: this.formData.accountId,
                        instagramActorId: this.formData.instagramActorId,
                        pageId: this.formData.pageId
                    };
                } else {
                    platformDetails = {
                        type: this.AccountTypesList.Google.id,
                        accountId: "123",
                        campaignProfileId: this.formData.campaignProfileId
                    };
                }
                const newPlatformAccount = {
                    platformAccount: {
                        order: this.newAccountOrder,
                        name: this.formData.accountName,
                        platformDetails,
                        lineType: "platformAccount",
                        status: "active"
                    },
                    rules: []
                };
                this.accountsList.push(newPlatformAccount);
                this.accountsList.sort(this.customSort);
                this.formData = {};
            } else if (data.accountAction === "edit") {
                let editedAccount = {};
                if (this.formData.accountType === this.AccountTypesList.Facebook.id) {
                    editedAccount = {
                        type: data.formData.accountType,
                        accountId: data.formData.accountId,
                        instagramActorId: data.formData.instagramActorId,
                        pageId: data.formData.pageId
                    };
                } else {
                    editedAccount = {
                        type: data.formData.accountType,
                        accountId: "123",
                        campaignProfileId: data.formData.campaignProfileId
                    };
                }
                this.accountsList[data.accountIndex].platformAccount.platformDetails = editedAccount;
                this.accountsList[data.accountIndex].platformAccount.name = data.formData.accountName;
            }
            this.toggleAddAccountModal();
        },

        onAccountRuleAction(data) {
            this.formData = data.formData;

            if (data.ruleAction === "add") {
                const newRule = {
                    name: data.formData.ruleName,
                    status: data.formData.status,
                    order: this.newRuleOrder,
                    signals: [],
                    targeting: [
                        {
                            name: "Locations",
                            value: "UK, US, AU"
                        },
                        {
                            name: "Audience",
                            value: "Age 20-30"
                        },
                        {
                            name: "Position",
                            value: "News Feed, ..."
                        }
                    ],
                    rulePlatformDetails: {
                        adSetId: data.formData.adSetId
                    }
                };
                this.accountsList[data.accountIndex].rules.push(newRule);
                this.accountsList[data.accountIndex].rules.sort(this.ruleCustomSort);
            } else if (data.ruleAction === "edit") {
                this.accountsList[data.accountIndex].rules[data.ruleIndex].name = data.formData.ruleName;
                this.accountsList[data.accountIndex].rules[data.ruleIndex].status = data.formData.status;
                if (!this.accountsList[data.accountIndex].rules[data.ruleIndex].rulePlatformDetails) {
                    this.accountsList[data.accountIndex].rules[data.ruleIndex].rulePlatformDetails = {};
                }

                this.accountsList[data.accountIndex].rules[data.ruleIndex].rulePlatformDetails.adSetId =
                    data.formData.adSetId;
            }
            this.toggleAccountRuleModal();
        },

        onAccountSignalAction(data) {
            this.formData = data.formData;

            if (data.signalAction === "add") {
                if (data.signals.length > 0) {
                    this.accountsList[data.accountIndex].rules[data.ruleIndex].signals.push(...data.signals);
                }
            } else {
                this.accountsList[data.accountIndex].rules[data.ruleIndex].signals = data.signals;
            }

            this.toggleAccountSignalModal();
        },

        onCancelChanges() {
            this.accountsList = deepClone(this.savedAccountsList);
        },

        onSaveChanges(usedForDraft) {
            this.isLoading = true;
            clearInterval(this.statusInterval);
            this.createCampaignFile(usedForDraft);
        },

        onShowDeletedAccounts(value) {
            this.showDeletedAccounts = value;
        },

        onSelectAllFilters(val) {
            this.selectedFilters.editableGroupValueIds = [];
            this.selectedFilters.languages = [];
            this.selectedFilters.masterTemplateIds = [];

            if (val) {
                this.$store.state.deliverableLibrary.filterOptions.editableGroups.forEach(el => {
                    el.options.forEach(optionsEl => {
                        this.selectedFilters.editableGroupValueIds.push(optionsEl.value);
                    });
                });

                this.$store.state.deliverableLibrary.filterOptions.languages.forEach(el => {
                    this.selectedFilters.languages.push(el.value);
                });

                this.$store.state.deliverableLibrary.filterOptions.masterTemplates.forEach(el => {
                    this.selectedFilters.masterTemplateIds.push(el.value);
                });
            }
        },

        onSaveVariantsSelection() {
            this.$set(
                this.accountsList[this.selectedAccount].rules[this.selectedRule],
                "variantsFilters",
                this.localSelectedFilters
            );
            this.onCloseVariantsSelection();
        },

        onCloseVariantsSelection() {
            this.isSelectVariantsVisible = false;
        },

        onSelectVariantsCount(count) {
            this.selectedVariantsCount = count;
        },

        onLoadingDeliverables(val) {
            this.isLoadingDeliverables = val;
        },

        async handleErrorResponse(response) {
            const responseValidateJson = await response.json();
            const responseMessage = responseValidateJson.message
                ? responseValidateJson.message
                : "There was an error please try again.";
            this.$snackbar.error(responseMessage);
            this.isLoading = false;
        },

        async getCampaignInfo() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-campaign/${this.campaignId}/accounts/`;
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    method: "GET",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    this.isLoading = false;
                    const responseValidateJson = await response.json();
                    if (responseValidateJson.data?.latestVersionId !== null) {
                        this.savedAccountsList = deepClone(
                            responseValidateJson.data.accountsRules.sort(this.customSort)
                        );
                        this.savedAccountsList.map(acc => {
                            return acc.rules.sort(this.ruleCustomSort);
                        });
                        this.accountsList = deepClone(responseValidateJson.data.accountsRules);
                        this.accountsList.map(acc => {
                            return acc.rules.sort(this.ruleCustomSort);
                        });
                        this.latestVersionId = responseValidateJson.data.latestVersionId;

                        this.removeDeletedGroups(this.accountsList);

                        clearInterval(this.statusInterval);
                        if (this.hasInProgressStatus) {
                            this.statusInterval = setInterval(() => {
                                this.getCampaignPublishStatus();
                            }, 5000);
                        }
                    }
                } else {
                    this.handleErrorResponse(response);
                }
            } catch (e) {
                console.log(e);
            }
        },

        async createCampaignFile(usedForDraft) {
            const { data } = await this.$apollo.mutate({
                mutation: createCampaignFile,
                variables: {
                    campaignId: this.campaignId
                }
            });
            this.tempCampaignFileLocation = data.createCampaignFile;
            if (data.createCampaignFile) {
                this.statusMessage = "Exporting campaign data";
                this.validateCampaignFile(usedForDraft);
            } else {
                this.isLoading = false;
                this.$snackbar.error("There was an error creating the campaign file");
            }
        },

        async validateCampaignFile(usedForDraft) {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-campaign/${this.campaignId}/validate/`;
                const fetcherBody = {
                    latestVersionId: this.latestVersionId,
                    accountsRules: this.accountsList,
                    fileUrl: this.tempCampaignFileLocation
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    const responseValidateJson = await response.json();
                    if (responseValidateJson.data) {
                        this.statusMessage = "Validating campaign rules";
                        this.saveCampaignFile(responseValidateJson.data, usedForDraft);
                    } else {
                        this.isLoading = false;
                        this.$snackbar.error("There was an error validating the campaign rules");
                    }
                } else {
                    this.handleErrorResponse(response);
                }
            } catch (e) {
                this.getCampaignInfo();
                console.log(e);
            }
        },

        async saveCampaignFile(data, usedForDraft) {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-campaign/${this.campaignId}/save`;
                const fetcherBody = {
                    ...data,
                    latestVersionId: this.latestVersionId
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    if (!usedForDraft) {
                        this.statusMessage = "Saving campaign rules";
                        this.generateDesiredState();
                    } else {
                        this.statusMessage = "Saving campaign";
                        this.isLoading = false;
                        this.$snackbar.success("Campaign is saved");
                        this.getCampaignInfo();
                    }
                } else {
                    this.handleErrorResponse(response);
                    this.getCampaignInfo();
                }
            } catch (e) {
                console.log(e);
                this.getCampaignInfo();
            }
        },

        async generateDesiredState() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-rules/build-desired-states`;
                const fetcherBody = {
                    campaignId: this.campaignId
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });

                if (response.ok) {
                    this.statusMessage = "Building desired state file";
                    this.publishCampaign();
                } else {
                    this.handleErrorResponse(response);
                    this.getCampaignInfo();
                }
            } catch (e) {
                console.log(e);
                this.getCampaignInfo();
            }
        },

        async publishCampaign() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-rules/platforms/publish`;
                const fetcherBody = {
                    campaignId: this.campaignId
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });

                if (response.ok) {
                    this.statusMessage = "Publishing campaign";
                    this.isLoading = false;
                    this.statusMessage = "";
                    const responsePublishJson = await response.json();
                    const isEmptyCampaign = responsePublishJson?.data.publishStatuses.every(
                        publishStatus => publishStatus.account.status === "deleted"
                    );
                    if (isEmptyCampaign) {
                        this.$snackbar.info("Campaign has no active accounts to publish");
                    } else {
                        this.$snackbar.success("Campaign is publishing");
                    }
                } else {
                    this.handleErrorResponse(response);
                }
                this.getCampaignInfo();
            } catch (e) {
                console.log(e);
            }
        },

        async getCampaignPublishStatus() {
            this.isPublishingStatusUpdating = true;
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-rules/campaigns/${this.campaignId}/status/`;
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    method: "GET",
                    apiKey: this.apiInfo.apiKey
                });
                if (!this.hasInProgressStatus) {
                    clearInterval(this.statusInterval);
                    return;
                }
                if (response.ok) {
                    this.isLoading = false;
                    const responseValidateJson = await response.json();
                    this.isPublishingStatusUpdating = false;
                    this.addPublishStatus(responseValidateJson);
                } else {
                    this.handleErrorResponse(response);
                    this.getCampaignInfo();
                }
            } catch (e) {
                console.log(e);
            }
        },

        deleteAccount(accountIndex) {
            this.accountsList[accountIndex].platformAccount.status = this.AccountStatus.Deleted;
            this.accountsList.sort(this.customSort);
        },

        editRule(accountIndex, ruleIndex) {
            this.ruleAction = "edit";
            this.editedRuleIndex = ruleIndex;
            this.editedAccountIndex = accountIndex;
            const editedRule = this.accountsList[accountIndex].rules[ruleIndex];
            this.formData = {
                ruleName: editedRule.name,
                adSetId: editedRule.rulePlatformDetails?.adSetId,
                status: editedRule?.status
            };
            this.toggleAccountRuleModal();
        },

        duplicateRule(accountIndex, rule) {
            const duplicatedRule = {
                order: this.newRuleOrder,
                isRuleActiveBySignals: false,
                name: `${rule.name} - Duplicated`,
                status: this.RuleStatus.Draft,
                rulePlatformDetails: rule.rulePlatformDetails,
                signals: rule.signals,
                targeting: rule.targeting,
                variantsFilters: rule.variantsFilters
            };

            this.accountsList[accountIndex].rules.push(duplicatedRule);
            this.accountsList[accountIndex].rules.sort(this.ruleCustomSort);
        },

        deleteRule() {
            this.accountsList[this.editedAccountIndex].rules[this.deletedRuleIndex].status = this.RuleStatus.Deleted;
            this.accountsList[this.editedAccountIndex].rules.sort(this.ruleCustomSort);
        },

        restoreRule(accountIndex, ruleIndex) {
            this.accountsList[accountIndex].rules[ruleIndex].status = this.RuleStatus.Draft;
            if (this.accountsList[accountIndex].platformAccount.status !== this.AccountStatus.Active) {
                this.restoreDeletedAccount(accountIndex);
            }
        },

        editAccount(accountIndex) {
            this.accountAction = "edit";
            this.selectedAccount = accountIndex;
            const editedAccount = this.accountsList[accountIndex].platformAccount;
            this.formData = {
                accountName: editedAccount.name,
                accountType: editedAccount.platformDetails.type,
                ...editedAccount.platformDetails
            };
            this.toggleAddAccountModal();
        },

        restoreDeletedAccount(accountIndex) {
            this.accountsList[accountIndex].platformAccount.status = this.AccountStatus.Active;
            this.accountsList[accountIndex].platformAccount.order = this.newAccountOrder;
            this.accountsList.sort(this.customSort);
        },

        editSignal(accountIndex, ruleIndex) {
            this.signalAction = "edit";
            this.addSignalCounter += 1;
            this.editedRuleIndex = ruleIndex;
            this.editedAccountIndex = accountIndex;
            const { signals } = this.accountsList[accountIndex].rules[ruleIndex];
            this.formData = {
                signals
            };
            this.toggleAccountSignalModal();
        },

        deleteSignal(accountIndex, ruleIndex, signalIndex) {
            this.accountsList[accountIndex].rules[ruleIndex].signals.splice(signalIndex, 1);
            this.$snackbar.info("Singal has been deleted");
        },

        removeDeletedGroups() {
            this.accountsList.forEach(account => {
                account.rules.forEach(rule => {
                    this.filterDeletedVariants(rule, "languages", "languages");
                    this.filterDeletedVariants(rule, "masterTemplateIds", "masterTemplates");
                });
            });
        },

        filterDeletedVariants(rule, filterKey, storeKey) {
            if (rule.variantsFilters && rule.variantsFilters[filterKey]) {
                const filterOptions = this.$store.state.deliverableLibrary.filterOptions[storeKey];
                const filteredArray = rule.variantsFilters[filterKey].filter(variantFilter =>
                    filterOptions.some(filterOption => filterOption.value === variantFilter)
                );
                Object.assign(rule.variantsFilters, { [filterKey]: filteredArray });
            }
        },

        hasActiveAccounts() {
            if (this.showDeletedAccounts) {
                return this.accountsList.length;
            }
            return !this.accountsList.every(account => account.platformAccount.status === this.AccountStatus.Deleted);
        },

        hasActiveElements(arr) {
            if (this.showDeletedAccounts) {
                return arr.length;
            }
            return !arr.every(obj => obj.status === this.AccountStatus.Deleted);
        },

        setEditedAccount(index) {
            this.editedAccountIndex = index;
        },

        updateAccountsOrder(val) {
            const removedElement = this.accountsList.splice(val.newIndex, 1)[0];
            this.accountsList.splice(val.newIndex, 0, removedElement);
            this.accountsList.forEach((obj, index) => {
                const updatedObj = { ...obj };
                updatedObj.platformAccount.order = index + 1;
                this.accountsList[index] = updatedObj;
            });
        },

        updateRulesOrder(val) {
            const editedRules = this.accountsList[this.editedAccountIndex].rules;

            const removedItem = editedRules.splice(val.oldIndex, 1)[0];
            editedRules.splice(val.newIndex, 0, removedItem);
            editedRules.forEach((item, index) => {
                const updatedItem = { ...item };
                updatedItem.order = index + 1;
                editedRules[index] = updatedItem;
            });
            this.accountsList[this.editedAccountIndex].rules = editedRules;
        },

        toggleSelectVariants(accountIndex = 0, ruleIndex = 0) {
            this.selectedAccount = accountIndex;
            this.selectedRule = ruleIndex;
            const variantsFilters = this.accountsList?.[this.selectedAccount]?.rules?.[this.selectedRule]
                ?.variantsFilters ?? {
                languages: [],
                masterTemplateIds: [],
                editableGroupValueIds: []
            };

            this.selectedFilters = JSON.parse(JSON.stringify(variantsFilters));
            this.isSelectVariantsVisible = !this.isSelectVariantsVisible;
        },

        selectedNumberOfVariants(accountIndex, ruleIndex) {
            const editableGroupsFilterOptions = this.$store.state.deliverableLibrary.filterOptions.editableGroups;
            const { variantsFilters } = this.accountsList[accountIndex].rules[ruleIndex];

            let result = 1;

            if (!variantsFilters?.editableGroupValueIds?.length) {
                return 0;
            }

            if (!editableGroupsFilterOptions.length) {
                setTimeout(() => {
                    this.selectedNumberOfVariants(accountIndex, ruleIndex);
                }, 2500);
                return 0;
            }

            if (variantsFilters && variantsFilters.languages) {
                result *= variantsFilters.languages.length;
            }

            if (variantsFilters.masterTemplateIds && variantsFilters.masterTemplateIds?.length > 1) {
                result *= variantsFilters.masterTemplateIds.length;
            }

            if (variantsFilters.editableGroupValueIds?.length > 1) {
                result *= editableGroupsFilterOptions.reduce((acc, group) => {
                    const count = group.options.filter(option =>
                        variantsFilters.editableGroupValueIds.includes(option.value)
                    ).length;
                    return acc * count;
                }, 1);
            }

            return result;
        },

        setMasterTemplateFilterOptions(masterTemplates) {
            /*
                We do this sort so that the same master templates get suffixed with
                the same number (if there are no changes to the master templates)
                by the generateLabel function if there are multiple master templates
                of the same size.
            */
            const masterTemplatesSortedById = [...masterTemplates].sort((a, b) => {
                if (a.created < b.created) {
                    return -1;
                }
                if (a.created > b.created) {
                    return 1;
                }
                return 0;
            });
            const generatedTemplateLabels = [];
            const masterTemplateOptions = [];
            masterTemplatesSortedById.forEach(masterTemplate => {
                const label = generateTemplateLabel(masterTemplate, generatedTemplateLabels);
                generatedTemplateLabels.push(label);
                masterTemplateOptions.push({
                    height: masterTemplate.width,
                    label,
                    value: masterTemplate._id,
                    width: masterTemplate.width
                });
            });
            /*
                We sort the options by label because it's nicer if the options
                are sorted by dimensions in the UI.
            */
            // eslint-disable-next-line complexity
            const masterTemplateOptionsSortedByDimensions = [...masterTemplateOptions].sort((a, b) => {
                if (a.width < b.width) {
                    return -1;
                }
                if (a.width > b.width) {
                    return 1;
                }
                if (a.height < b.height) {
                    return -1;
                }
                if (a.height > b.height) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
            this.$store.dispatch(
                DeliverableLibraryAction.SetMasterTemplateFilterOptions,
                masterTemplateOptionsSortedByDimensions
            );
        },

        setEditableGroupFilterOptions(editableGroupValues) {
            const optionsByEditableGroup = editableGroupValues.reduce((acc, { _id, editableGroupName, value }) => {
                if (!acc[editableGroupName]) {
                    acc[editableGroupName] = {
                        label: formatGroupName(editableGroupName),
                        name: editableGroupName,
                        options: []
                    };
                }
                acc[editableGroupName].options.push({
                    label: value,
                    value: _id
                });
                return acc;
            }, {});
            const editableGroupsWithOptions = Object.values(optionsByEditableGroup);
            this.$store.dispatch(DeliverableLibraryAction.SetEditableGroupFilterOptions, editableGroupsWithOptions);
        },

        getSignalDescription(signal) {
            let description = "";
            if (signal.signalType === "DATETIME") {
                const editedRule = rrulestr(signal.value);
                description = "Signal based on <strong>Date and Time</strong> which triggers";
                if (!editedRule.options.until) {
                    description += ` from <strong>${editedRule.options.dtstart}</strong>`;
                } else {
                    description += ` between <strong>${editedRule.options.dtstart}</strong> and <strong>${editedRule.options.until}</strong>`;
                }
                if (editedRule.options.byweekday) {
                    const days = editedRule.options.byweekday.map(day => this.weekDays[day].label);
                    description += ` across these days <strong>${days.join(", ")}</strong>`;
                }
                if (editedRule.options.tzid) {
                    const timezone = this.timezones.find(tz => tz.value === editedRule.options.tzid);
                    description += ` in this time zone of <strong>${timezone.label}</strong>`;
                }
            } else if (signal.signalType === "OPEN_WEATHER_TEMPERATURE") {
                description =
                    "Signal based on <strong>Weather</strong> which triggers when <strong>Temperature</strong> is";
                const signalValue = parseTemperatureValueString(signal.value);

                const operator = Object.values(this.TemperatureOperatorTypesList).find(
                    op => op.sign === signalValue.operator
                );

                description += ` <strong>${operator.name}</strong> than <strong>${signalValue.value} °c</strong>`;
            } else {
                const weatherSignalName = Object.values(this.SignalTypesList).find(sig => sig.id === signal.signalType);
                const precipitationAmmountName = Object.values(this.PrecipitationAmountTypesList).find(
                    sig => sig.id === signal.value
                );
                description = `Signal based on <strong>Weather</strong> which triggers when <strong>${weatherSignalName.name}</strong> is <strong>${precipitationAmmountName.name}</strong>`;
            }
            if (signal.locations?.length > 0) {
                const locationName = signal.locations[0].locationRef.split(":q:").slice(-1)[0];
                description += ` in the location of <strong>${locationName}</strong>`;
            }
            return `${description}.`;
        },

        setSignalDescription(signal, signalIndex, ruleIndex, accountIndex, numberOfSignals) {
            const refName = `signalDescriptions${signalIndex}${ruleIndex}${accountIndex}`;
            const refElement = this.$refs[refName];
            if (refElement) {
                let text = this.getSignalDescription(signal);
                if (signalIndex !== numberOfSignals - 1) {
                    text += "<br/><strong><span class='intelligent-delivery__highlighted'>AND</span></strong>";
                }
                refElement[0].innerHTML = text;
            }
        },

        getSignalStatus(rule) {
            if (rule?.status === this.RuleStatus.Draft) {
                return "Draft";
            }
            return rule?.isRuleActiveBySignals ? "Triggered" : "Not triggered";
        },

        getStatusClass(status) {
            switch (status) {
                case this.RuleStatus.Active:
                    return "intelligent-delivery__status blue";
                case this.RuleStatus.Deleted:
                    return "intelligent-delivery__status red";

                default:
                    return "intelligent-delivery__status";
            }
        },
        openDeleteRuleModal(accountIndex, ruleIndex) {
            this.editedAccountIndex = accountIndex;
            this.deletedRuleIndex = ruleIndex;
            this.deleteRuleModal = true;
        }
    },

    apollo: {
        intelligentDeliveryApiInfo: {
            query: getIntelligentDeliveryApiInfoQuery,
            result({ data }) {
                this.apiInfo = data.intelligentDeliveryApiInfo;
                this.getCampaignInfo();
            }
        },

        computedDeliverableComponents: {
            query: computedDeliverableComponentsQuery,
            fetchPolicy: "network-only",
            loadingKey: "loadingLibraryFilterOptions",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingFilterOptions = true;
                    return;
                }
                this.hasErrorLoadingFilterOptions = false;
                const { editableGroupValues, languages, masterTemplates } = data.computedDeliverableComponents;
                this.$store.dispatch(DeliverableLibraryAction.SetLanguageFilterOptions, languages);
                this.setMasterTemplateFilterOptions(masterTemplates);
                this.setEditableGroupFilterOptions(editableGroupValues);
                this.removeDeletedGroups(this.accountsList);
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        if (this.hasAccountChanges) {
            this.nextFunction = next;
            this.toggleDiscardChangesModal();
        } else {
            next();
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";
.app-wrapper.wpp-theme--intelligentdelivery .campaign-content .main-content {
    padding-top: 0;
    margin-top: 60px;
}
.intelligent-delivery {
    &__wrapper {
        padding: 0px 20px;
        max-width: 100vw;
        .save-changes {
            display: flex;
        }
    }
    &-accounts__draggable-wrapper {
        display: flex;
        padding: 20px 0;
        flex-direction: column;
    }
    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: -20px 0 0 0;
        width: 100%;
        height: 60px;
        position: fixed;
        z-index: 2;
        left: 0;
        top: 73px;
        overflow: visible;
        padding: 0 20px;
        background: #fff;
        border-bottom: 1px solid #c1c7cd;

        &-wrapper {
            flex: 1;
            display: flex;
            width: 100%;
        }

        &-action-wrapper {
            flex: 1;
            display: flex;
            width: 100%;
            align-items: center;

            &.right {
                display: flex;
                justify-content: flex-end;
                margin-left: auto;
                white-space: nowrap;
            }
        }

        &-action-select {
            max-width: 175px;
        }

        .notification-label {
            width: auto;
            margin-left: 20px;
            padding-right: 10px;
        }

        .save-changes {
            button {
                &:not(:first-child) {
                    margin-left: 15px;
                }
            }
        }
    }
    &__account {
        margin-bottom: 20px;
        &-title {
            display: flex;
            margin-bottom: 10px;
        }
        &-name {
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            min-width: 80%;
            svg {
                max-height: 32px;
                margin-right: 10px;
            }
        }
        &-actions {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 10px;
            i {
                cursor: pointer;
            }
            .intelligent-delivery__section-drag-handle {
                margin-top: 0;
                margin-left: 10px;
                cursor: pointer;
            }
        }
        &-rules {
            width: 100%;
            padding: 16px 0 0 0;
            background-color: $white;
            margin-bottom: 24px;
        }
        &-signals {
            padding: 13px 0;
            border-bottom: 1px solid var(--wpp-grey-color-400);
            border-top: 1px solid var(--wpp-grey-color-300);
            table {
                width: 100%;
                text-align: left;
                border-collapse: collapse;

                th,
                td {
                    padding: 0 16px;
                }
                tr {
                    th {
                        padding-bottom: 13px;
                    }
                    td {
                        padding-top: 13px;
                    }
                }
            }
        }
        &-variants {
            padding: 8px 16px 16px 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
    &__signal {
        &-actions {
            display: flex;
            flex-direction: row;
            gap: 5px;
            height: 100%;
            align-items: center;
            justify-content: center;
            margin-left: auto;

            a {
                opacity: 0.7;
                transition: all 0.5;
                display: flex;
                align-items: center;
                gap: 3px;
                cursor: pointer;
                &:hover {
                    opacity: 1;
                }
            }
        }
        &-name,
        &-status {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            border-bottom: 1px solid var(--wpp-grey-color-600);
        }
        &-name {
            width: 85%;
        }
        &-status {
            width: 15%;
        }
        &-add {
            padding: 5px 16px;

            a {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                padding: 5px;
                display: flex;
                align-items: center;
                gap: 4px;
                width: max-content;
            }
        }
        &-add-icon {
            border: 1px solid var(--wpp-primary-color-500);
            border-radius: 50%;
        }
    }
    &__rule {
        &-row {
            background: #fff;
            border-top: 0;
            border-bottom: 1px solid var(--wpp-grey-color-200);
            &:last-child {
                border-bottom: 0;
            }
            &.intelligent-delivery__rule-row--add {
                border-top: 1px solid var(--wpp-grey-color-300);
                border-bottom: 0;
                background: none;
                a {
                    color: var(--wpp-primary-color-900);
                    font-weight: 600;
                }
            }
        }
        &-disabled {
            .intelligent-delivery__account-variants,
            .intelligent-delivery__account-signals,
            .intelligent-delivery__signal-add {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        &-header {
            padding-bottom: 16px;
            margin: 0 16px;
            border-bottom: 1px solid var(--wpp-grey-color-400);
            display: flex;
            align-items: center;
            gap: 10px;
            &-content {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
        &-number {
            display: inline-block;
            padding-left: 5px;
            font-size: 12px;
            line-height: 16px;
        }
        &-variants {
            color: #5e00b5;
            cursor: pointer;
        }
        &-name {
            font-size: 16px;
            font-weight: 600;
        }
    }
    &__no-data {
        border: 1px solid #c1c7cd;
        padding: 5px;
    }
    &__dashboard {
        position: relative;
        height: 100%;
        width: 100%;
        > div {
            width: 100%;
            height: 100%;
        }
        .empty-message {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .loading-layer--theme-light {
            position: absolute;
            top: 1px;
        }
    }
    &__add-rule {
        width: 100%;
    }
    &__no-signals {
        padding: 0 16px;
    }
    &__status {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        padding: 1px 8px;
        background-color: var(--wpp-grey-color-300);
        border-radius: 4px;
        color: var(--wpp-grey-color-600);
        text-transform: capitalize;
        width: max-content;
        &.green {
            color: #2ac63f;
            background-color: rgba(#9eeba8, 0.2);
        }
        &.blue {
            color: var(--wpp-primary-color-500);
            background-color: var(--wpp-primary-color-100);
        }
        &.red {
            color: #ea2a4a;
            background-color: rgba(#ea2a4a, 0.2);
        }
    }
    &__highlighted {
        color: var(--wpp-primary-color-500);
    }
    &__signal-container {
        cursor: pointer;
    }
}
.rule-edit-modal {
    &__platform {
        li {
            display: flex;
            align-items: center;
            svg {
                height: 32px;
                width: 32px;
                margin-right: 10px;
            }
        }
    }
}
</style>
