<template>
    <div class="user-permission-modal">
        <hox-modal @close="close">
            <template #header>
                <div v-if="newUser" class="user-permission-modal__header">Add User</div>
                <div v-else class="user-permission-modal__header">
                    User Permissions
                    <span class="user-permission-modal__header-subtitle">{{ user.id }}</span>
                </div>
            </template>

            <hox-alert
                v-if="isDisabled"
                type="warning"
                size="small"
                align-text="center"
                class="user-permission-modal-alert"
            >
                <template #content>
                    <p>You cannot modify your own permissions</p>
                </template>
            </hox-alert>

            <div v-if="newUser" class="user-permission-modal-newuser">
                <Form
                    ref="newUserForm"
                    label-position="left"
                    :model="newUserData"
                    :rules="validationRules"
                    @submit.native.prevent="createUser"
                >
                    <FormItem ref="newUserFormName" label="Email" prop="id" :label-width="60">
                        <Input
                            v-model="newUserData.id"
                            data-testid="add-user-modal__email"
                            placeholder="Email"
                            width="200"
                        ></Input>
                    </FormItem>
                    <FormItem label="Role" prop="roleId" :label-width="60">
                        <user-role-select
                            v-model="newUserData.roleId"
                            data-testid="add-user-modal__role"
                            :disabled="roleDisabled"
                        ></user-role-select>
                    </FormItem>
                    <FormItem v-model="newUserData.scope" prop="scope">
                        <user-scope-select
                            v-model="newUserData.scope"
                            :data="tableData"
                            :clients="allScopeClients"
                            :client-campaigns="clientCampaigns"
                            :show-empty-row="true"
                            @input="onNewUserScopeChange"
                        />
                    </FormItem>
                </Form>
            </div>

            <user-scope-select
                v-else
                :data="tableData"
                :disabled="isDisabled"
                :clients="allScopeClients"
                :client-campaigns="clientCampaigns"
                :show-empty-row="true"
                @remove-client-scope="removeClientScope"
                @user-scope-update="onUserScopeUpdate"
            />

            <template v-if="newUser" #footer>
                <Button
                    class="user-permission-modal-button__cancel"
                    type="text"
                    data-testid="add-user-modal__cancel-button"
                    @click="close"
                >
                    Cancel
                </Button>
                <Button
                    class="user-permission-modal-button__add"
                    type="primary"
                    data-testid="add-user-modal__add-button"
                    @click="createUser"
                >
                    Add User
                </Button>
            </template>
            <template v-else #footer>
                <Button
                    v-if="$auth.userCan($auth.Actions.CanRemoveUser)"
                    class="user-permission-modal-button__delete"
                    icon="ios-trash-outline"
                    type="default"
                    data-testid="add-user-modal__delete-button"
                    @click="confirmDelete"
                >
                    Delete User
                </Button>
            </template>
        </hox-modal>
    </div>
</template>
<script>
import HoxModal from "@/components/Modal/Modal/Modal";
import HoxAlert from "@/components/common/Alert";

import AddUserScopes from "@/apollo/mutations/AddUserScopes.gql";
import RemoveUserScopes from "@/apollo/mutations/RemoveUserScopes.gql";

import AddUserMutation from "@/apollo/mutations/AddUser.gql";
import RemoveUserMutation from "@/apollo/mutations/RemoveUser.gql";

import UserRoleSelect from "@/components/ManageUsers/UserRoleSelect";
import UserScopeSelect from "@/components/ManageUsers/UserScopeSelect";

import { RolesGetter } from "@/store/modules/roles";
import { debounce, getProvider } from "@/utils";
import { AuthGetters } from "@/store/modules/auth";

export default {
    name: "UserAccessScopeModal",
    components: {
        UserScopeSelect,
        UserRoleSelect,
        HoxAlert,
        HoxModal
    },

    props: {
        newUser: {
            type: Boolean
        },

        user: {
            type: Object
        }
    },

    data() {
        return {
            allClientCampaigns: {},
            currentUser: null,
            roleDisabled: true,

            newUserData: {
                id: "",
                roleId: "",
                scope: []
            },

            validationRules: {
                id: [
                    {
                        required: true,
                        message: "Email cannot be empty",
                        trigger: "blur"
                    },
                    {
                        type: "email",
                        message: "Incorrect email format",
                        trigger: "blur"
                    },
                    {
                        type: "string",
                        required: true,
                        message: "Please use the @hogarth.com domain",
                        validator: (rule, value) => !value.match(/@hogarthww.com$/)
                    }
                ],
                roleId: [
                    {
                        required: true,
                        message: "Role is mandatory",
                        trigger: "blur"
                    }
                ],

                scope: [
                    {
                        required: true,
                        type: "array",
                        min: 1,
                        message: "User needs to be added to at least one brand with a campaign",
                        trigger: "custom"
                    }
                ]
            }
        };
    },

    computed: {
        allScopeClients() {
            let scopeClients = {};
            let scopeCampaign = {};

            if (this.currentUser.scope.client) {
                scopeClients = this.currentUser.scope.client.reduce((acc, cur) => {
                    if (this.hasScope({ clientId: cur._id })) {
                        return Object.assign(acc, { [cur._id]: cur });
                    }

                    return acc;
                }, {});
            }

            if (this.currentUser.scope.campaign) {
                scopeCampaign = this.currentUser.scope.campaign.reduce((acc, cur) => {
                    const clnt = this.currentUser.scope.campaignOnlyClient.find(cl => cl.id === cur.clientId);

                    if (
                        clnt !== undefined &&
                        (this.hasScope({ clientId: clnt._id }) ||
                            this.hasScope({
                                clientId: clnt._id,
                                campaignId: cur._id
                            }))
                    ) {
                        return Object.assign(acc, { [clnt._id]: clnt });
                    }

                    return acc;
                }, {});
            }

            return {
                ...scopeClients,
                ...scopeCampaign
            };
        },

        clientCampaigns() {
            const clientCampaigns = {};

            if (this.currentUser.scope.client) {
                this.currentUser.scope.client.forEach(clientScope => {
                    clientCampaigns[clientScope._id] = ["all"];
                });
            }

            if (this.currentUser.scope.campaign) {
                this.currentUser.scope.campaign.forEach(campaign => {
                    const client = this.currentUser.scope.campaignOnlyClient.find(cl => cl.id === campaign.clientId);
                    clientCampaigns[client._id] = clientCampaigns[client._id] || [];
                    clientCampaigns[client._id].push(campaign);
                });
            }

            return clientCampaigns;
        },

        collaboratorRoleId() {
            const collaboratorRole = this.$store.getters[RolesGetter.getCollaboratorRole];
            return collaboratorRole ? collaboratorRole._id : null;
        },

        isDisabled() {
            return this.user && this.userId === this.user.id;
        },

        isSuperAdmin() {
            return this.$store.getters[AuthGetters.isSuperAdmin];
        },

        tableData() {
            return Object.keys(this.allScopeClients).map(clientId => this.allScopeClients[clientId]);
        },

        userId() {
            return this.$store.state.auth.me && this.$store.state.auth.me.id;
        }
    },

    watch: {
        "newUserData.id": {
            // here we use debounce so that the validation and the role disable
            // does not happen to often as the enabling and disabling of the
            // field as regularly as the user types looks strange
            // A named function is used here over an arrow function as
            // the function to be debounced needs it's only context
            handler: debounce(function handler() {
                if (this.$refs.newUserForm) {
                    this.$refs.newUserForm.validateField("id", error => {
                        if (!error) {
                            if (!getProvider(this.newUserData.id) && !this.isSuperAdmin()) {
                                this.roleDisabled = true;
                                this.newUserData.roleId = this.collaboratorRoleId;
                            } else {
                                this.roleDisabled = false;
                            }
                        } else {
                            this.roleDisabled = true;
                        }
                    });
                }
            }, 500)
        }
    },

    created() {
        this.currentUser = { ...this.user };
        if (this.isSuperAdmin) {
            this.roleDisabled = false;
        }

        if (this.newUser) {
            this.currentUser = {
                scope: {
                    client: null,
                    campaign: null
                }
            };

            this.newUserData.roleId = this.collaboratorRoleId;
        }
    },

    methods: {
        addUser() {
            this.$refs.newUserForm.validate(valid => {
                if (valid) {
                    this.createUser();
                }
            });
        },

        close() {
            if (this.newUser) {
                this.newUserData = {
                    id: "",
                    roleId: this.collaboratorRoleId,
                    scope: []
                };
            }

            this.$emit("close");
        },

        confirmDelete() {
            this.$Modal.confirm({
                title: `Delete user ${this.user.id}?`,
                content: `Removing "${this.user.id}" is permanent and can not be undone.`,
                okText: "Delete",
                onOk: this.deleteUser
            });
        },

        hasScope(scope) {
            return this.$auth.hasScope(scope);
        },

        createUser() {
            this.$refs.newUserForm.validate(async valid => {
                if (!valid) {
                    return;
                }

                try {
                    const response = await this.$apollo.mutate({
                        mutation: AddUserMutation,
                        variables: {
                            id: this.newUserData.id,
                            roleId: this.newUserData.roleId,
                            scopes: this.newUserData.scope
                        }
                    });

                    if (response?.data?.addUser?.isNewUser === true) {
                        this.$snackbar.success(
                            `The user with the email address <strong>${this.newUserData.id}</strong> has been added.`
                        );
                        this.$emit("userCreated");
                        this.close();
                    } else {
                        this.$snackbar.error(
                            `A user with the email address <strong>${this.newUserData.id}</strong> already exists.`
                        );
                    }
                } catch ({ message }) {
                    this.$refs.newUserFormName.validateState = "error";
                    this.$refs.newUserFormName.validateMessage = message;
                }
            });
        },

        async deleteUser() {
            try {
                await this.$apollo.mutate({
                    mutation: RemoveUserMutation,
                    variables: {
                        id: this.user.id
                    }
                });

                this.$snackbar.success(`${this.user.id} has been deleted`);
                this.$emit("userDeleted");
                this.close();
            } catch ({ message }) {
                this.userExistsErrorMsg = message;
                this.$snackbar.error(`Failed to delete user ${this.user.userId}`, message);
            }
        },

        onNewUserScopeChange() {
            this.$refs.newUserForm.validateField("scope");
        },

        // eslint-disable-next-line complexity
        async onUserScopeUpdate(userScopeData) {
            /* {
                clientId
                removeUserScope: false,
                addUserScope: false,
                campaignIdsToAdd: [],
                campaignIdsToRemove: []
            } */

            try {
                if (userScopeData.addUserScope) {
                    const result = await this.$apollo.mutate({
                        mutation: AddUserScopes,
                        variables: {
                            id: this.user.id,
                            scopes: [{ clientId: userScopeData.clientId }]
                        }
                    });

                    this.currentUser = result.data.addUserScopes;

                    return;
                }

                if (userScopeData.removeUserScope) {
                    const result = await this.$apollo.mutate({
                        mutation: RemoveUserScopes,
                        variables: {
                            id: this.user.id,
                            scopes: [{ clientId: userScopeData.clientId }]
                        }
                    });

                    this.currentUser = result.data.removeUserScopes;
                }

                const campaignScopesToAdd = userScopeData.campaignIdsToAdd.map(cmpId => ({
                    clientId: userScopeData.clientId,
                    campaignId: cmpId
                }));

                if (campaignScopesToAdd.length) {
                    const result = await this.$apollo.mutate({
                        mutation: AddUserScopes,
                        variables: {
                            id: this.user.id,
                            scopes: campaignScopesToAdd
                        }
                    });

                    this.currentUser = result.data.addUserScopes;
                }

                const campaignScopesToRemove = userScopeData.campaignIdsToRemove
                    .filter(cmpId => cmpId !== undefined)
                    .map(cmpId => ({
                        clientId: userScopeData.clientId,
                        campaignId: cmpId
                    }));

                if (campaignScopesToRemove.length) {
                    const result = await this.$apollo.mutate({
                        mutation: RemoveUserScopes,
                        variables: {
                            id: this.user.id,
                            scopes: campaignScopesToRemove
                        }
                    });

                    this.currentUser = result.data.removeUserScopes;
                }

                this.$snackbar.success("User scope has been updated");
            } catch (e) {
                this.$snackbar.error("User scope could not been update", e.message);
            }
        },

        async removeClientScope(clientId) {
            const result = await this.$apollo.mutate({
                mutation: RemoveUserScopes,
                variables: {
                    id: this.user.id,
                    scopes: [{ clientId }]
                }
            });

            this.currentUser = result.data.removeUserScopes;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.user-permission-modal {
    &-alert {
        margin-top: 20px;

        .alert__icon-container {
            padding-bottom: 0;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;

        &-subtitle {
            padding-top: 8px;
            font-weight: normal;
            font-size: 12px;
        }
    }

    &-newuser {
        padding-bottom: 24px;

        .ivu-form-item-content {
            line-height: 21px;
        }
    }

    &-add-client {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 8;
    }

    &-table {
        th {
            .ivu-table-cell {
                padding-left: 0;
                padding-right: 0;
            }
        }

        td {
            .ivu-table-cell {
                padding: 0 8px;
                margin-bottom: 6px;
            }

            &.bordered-column > .ivu-table-cell {
                border: 1px solid $grey3;
                height: 34px;
            }

            &.bordered-column.client-name > .ivu-table-cell {
                padding: 6px 8px;
            }
        }

        .new-entry-row {
            td.bordered-column > .ivu-table-cell {
                background-color: $grey1;
            }

            td.bordered-column.client-name > .ivu-table-cell {
                padding: 5px 0 4px 0;
            }
        }

        .client-campaigns > .ivu-table-cell {
            padding: 6px 6px 6px 0;
            margin-bottom: 6px;
        }

        &--ghost {
            border: 0 none transparent;

            .ivu-table {
                &::before,
                &::after {
                    background-color: inherit;
                }

                th,
                td {
                    background-color: inherit;
                    border-color: transparent;
                    height: 25px;
                }

                table {
                    border-color: transparent;
                    background-color: inherit;
                }
            }
        }

        &-wrapper {
            position: relative;
        }

        .select-client-notice {
            padding-left: 6px;
        }

        .ivu-icon-md-close {
            cursor: pointer;
        }
    }

    &-button__delete {
        margin-right: auto;
    }
}
</style>
