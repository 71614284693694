/**
 * Decode a manifest string so we can populate it with values
 */
export function decodeManifest(manifest) {
    let decoded;
    // Check if the str starts with an encoded { before decoding: { = %7B, % = %25 - so double encoded it is %257B
    if (manifest.trimStart().startsWith("%257B")) {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        decoded = JSON.parse(decodeURIComponent(unescape(manifest)));
    }
    else {
        decoded = JSON.parse(manifest);
    }
    if (typeof decoded !== "object" || decoded === null) {
        throw new Error("JSON is not a Manifest");
    }
    return decoded;
}
/**
 * Encode a manifest object so it can be injected into HTML
 */
export function encodeManifest(manifest) {
    /*
     * Unfortunately, the current hoxton.js v5 used by templates expects data to be 'escaped'
     * even though I don't think it's technically necessary, encodeURI should be enough.
     */
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    return escape(encodeURI(JSON.stringify(manifest)));
}
/* TODO: maybe some unit tests */
