<template>
    <div>
        <div class="advanced-model-options__input">
            <label>Image size</label>
            <Select :value="customInputs.size" @input="onUpdateImageSize">
                <Option v-for="size in customOptions.size.options" :key="size" :value="size">
                    {{ size }}
                </Option>
            </Select>
        </div>
        <div>
            <Button type="primary" @click="onSaveUpdates">Save</Button>
        </div>
    </div>
</template>
<script>
import { ModelDefaultValues, ImagineModel } from "@/enums/imagine";
import { ImagineAction } from "@/store/modules/imagine";

const customOptions = {
    size: {
        type: "select",
        defaultValue: ModelDefaultValues[ImagineModel.Dalle3].size,
        options: ["256x256", "512x512", "1024x1024"]
    }
};
export default {
    name: "AdvancedDalleOptions",
    props: {
        model: {
            type: String
        }
    },

    data() {
        return {
            customInputs: {
                size: customOptions.size.defaultValue
            }
        };
    },

    computed: {
        savedAdvancedOptions() {
            return this.$store.state.imagine.advancedOptions[ImagineModel.Dalle3];
        },

        useAdvancedOptions() {
            return this.$store.state.imagine.useAdvancedOptions;
        }
    },

    created() {
        this.customOptions = customOptions;

        if (this.savedAdvancedOptions && this.useAdvancedOptions) {
            const merged = { ...this.customInputs, ...this.savedAdvancedOptions };

            this.customInputs = merged;
        }
    },

    methods: {
        onUpdateImageSize(val) {
            this.customInputs.size = val;
        },

        onSaveUpdates() {
            this.$emit("updatedCustomInputs", this.customInputs);

            this.$store.dispatch(ImagineAction.SetAdvancedOptions, {
                ...this.$store.state.imagine.advancedOptions,
                [this.model]: {
                    ...this.customInputs
                }
            });
        }
    }
};
</script>
