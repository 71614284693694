<template>
    <div
        ref="popperTooltip"
        :class="[
            'creative-insights-chart__popper-tooltip',
            { 'creative-insights-chart__popper-tooltip--hidden': isPopperTooltipHidden }
        ]"
    >
        <dl class="creative-insights-chart__popper-tooltip-list">
            <div v-for="{ label, stats, attributeConfidence } in pointLabels" :key="label">
                <dt>{{ label }}</dt>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <dd v-if="stats" v-html="getStatsText(stats, attributeConfidence)"></dd>
            </div>
        </dl>
        <div v-if="isImageLoading" class="creative-insights-chart__popper-tooltip-img-loader">
            <hox-loading-spinner :size="40" />
        </div>
        <span
            ref="popperTooltipLoading"
            :class="['creative-insights-chart__popper-tooltip-animation', { animate: isTooltipAnimated }]"
        ></span>
        <div
            v-show="!isImageLoading"
            ref="tooltipImagePlaceholder"
            class="creative-insights-chart__popper-tooltip-img-placeholder"
        ></div>
    </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { kpiDisplayFormatter, utilisationDisplayFormatter } from "@/utils/creativeInteligence";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

export default {
    name: "ChartTooltip",

    props: {
        pointLabels: {
            type: Array
        },
        imageUrls: {
            type: Array
        }
    },

    data() {
        return {
            tooltipCarouselTimeout: undefined,
            isPopperTooltipHidden: true,
            isTooltipAnimated: false,
            isImageLoading: false,
            popperInstance: null
        };
    },

    watch: {
        imageUrls: {
            async handler() {
                this.stopTooltipCarousel();
                await this.populateImages();
            },
            immediate: true
        }
    },

    beforeDestroy() {
        this.stopTooltipCarousel();
    },

    methods: {
        createTooltipImage(url) {
            return new Promise((resolve, reject) => {
                const image = new Image();
                const onLoad = () => {
                    resolve(image);
                };
                image.className = "creative-insights-chart__popper-tooltip-img";
                image.onload = onLoad.bind(this);
                image.onerror = () => {
                    reject();
                };
                image.src = url;
            });
        },

        getStatsText(summaryStats, attributeConfidence) {
            if (!summaryStats) {
                return "";
            }
            const { count, totalCount, utilisation, kpiMetric } = summaryStats;
            const kpiStat = this.$store.getters[CreativeInsightsGetters.KpiMetricStat];
            const kpiValue = kpiDisplayFormatter(kpiMetric[kpiStat]);
            const utilisationValue = utilisationDisplayFormatter(utilisation);
            const attributeConfidenceValue = attributeConfidence
                ? ` (Confidence: ${Math.round(attributeConfidence)}%)`
                : "";

            return `Found in ${utilisationValue} of ads (${count} of ${totalCount})<br />KPI: ${kpiValue} ${attributeConfidenceValue}`;
        },

        async populateImages() {
            if (this.$refs.tooltipImagePlaceholder) {
                this.$refs.tooltipImagePlaceholder.innerHTML = "";
                this.isPopperTooltipHidden = false;
            }
            let maxHeight = 0;
            if (this.imageUrls.length) {
                this.isImageLoading = true;
                const imageElements = await Promise.all(
                    this.imageUrls.map(imageUrl => this.createTooltipImage(imageUrl))
                );
                maxHeight = Math.max(...imageElements.map(({ height }) => height));
                // to do: move to property
                imageElements.forEach(element => {
                    this.$refs.tooltipImagePlaceholder.append(element);
                });
                imageElements[0].classList.add("active");
                this.isImageLoading = false;
                this.startTooltipCarousel();
            }
            if (this.$refs.tooltipImagePlaceholder) {
                this.$refs.tooltipImagePlaceholder.setAttribute("style", `height:${maxHeight}px`);
            }
        },

        initPopper() {
            if (this.popperInstance) {
                this.popperInstance.destroy();
            }
            this.isPopperTooltipHidden = false;
            this.popperInstance = createPopper(this.$parent.$refs.popperAnchor, this.$refs.popperTooltip, {
                placement: "top-start",
                modifiers: [
                    {
                        name: "preventOverflow",
                        options: {
                            boundary: this.$parent.$refs.boundaryElement
                        }
                    },
                    {
                        name: "offset",
                        options: {
                            offset: [6, 6]
                        }
                    }
                ]
            });
        },

        resetPopper() {
            if (this.popperInstance) {
                this.popperInstance.destroy();
                this.resetTooltipAnimation();
                this.stopTooltipCarousel();
                this.isPopperTooltipHidden = true;
            }
        },

        async resetTooltipAnimation() {
            this.isTooltipAnimated = false;
            this.$refs.popperTooltipLoading.style.animation = null;
            await this.$nextTick();
            // trigger DOM reflow to reset animation by requesting element dimensions
            return this.$refs.popperTooltipLoading.offsetHeight;
        },

        startTooltipCarousel() {
            this.stopTooltipCarousel();
            const tooltipImages = this.$refs.tooltipImagePlaceholder.children;
            if (tooltipImages.length > 1) {
                this.isTooltipAnimated = true;
            }
            Object.values(tooltipImages).forEach(tooltipImage => tooltipImage.classList.remove("active"));
            if (tooltipImages[0]) {
                tooltipImages[0].classList.add("active");
            }
            let carouselStart = 0;
            this.tooltipCarouselTimeout = setInterval(async () => {
                await this.resetTooltipAnimation();
                if (carouselStart === tooltipImages.length - 1) {
                    carouselStart = 0;
                } else {
                    carouselStart += 1;
                }
                Object.values(tooltipImages).forEach(tooltipImage => tooltipImage.classList.remove("active"));
                tooltipImages[carouselStart].classList.add("active");
                if (tooltipImages.length > 1) {
                    this.isTooltipAnimated = true;
                }
            }, 1500);
        },

        stopTooltipCarousel() {
            clearInterval(this.tooltipCarouselTimeout);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.creative-insights-chart {
    &__popper-tooltip {
        position: relative;
        z-index: 100;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: space-between;
        width: 20vh;
        max-width: 300px;
        background: #fff;

        &--hidden {
            position: absolute;
            left: -10000px !important;
            top: auto;
            overflow: hidden;
        }

        &-list {
            font-size: 11px;
            background-color: transparentize($darktheme40, 0.1);
            border-radius: $border-radius-base;
            color: $white;
            padding: $spacing-smaller $spacing-smaller;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
            list-style: none;

            dl {
                padding: 0;
            }

            dt {
                margin-left: 0;
            }

            dd {
                margin-left: $spacing-semi-small;
            }
        }

        &-img {
            width: 100%;
            height: 100%;
            max-height: 250px;
            object-fit: contain;
            background-color: $white;
        }

        &-img-loader {
            padding: $spacing-small;
            margin: auto;
            height: 250px;
        }

        &-img-placeholder,
        &-img-loader {
            width: 20vh;
            max-width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 4px;
            max-height: 250px;
            background-color: $white;
            margin-top: -5px;
        }
    }

    &__tooltip {
        font-size: 12px;
        z-index: 20;
        width: 1px;
        height: 0;
        background-color: red;
        border-radius: 50%;
        pointer-events: none;
    }

    &__tooltip-entry-content {
        cursor: pointer;
    }

    &__popper-tooltip-img {
        display: none;
        opacity: 0;
        &.active {
            display: inline-block;
            transition: opacity 0.6s linear;
            opacity: 1;
        }
    }
    &__popper-tooltip-animation {
        height: 5px;
        width: 0%;
        background: $blue;
        position: relative;
        top: 0;
        left: 0;
        z-index: 100;
        &.animate {
            width: 0%;
            animation: load 1.5s normal forwards;
        }
    }
}
@keyframes load {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
</style>
