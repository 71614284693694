<template>
    <hox-modal v-if="!isPermissionGranted">
        <hox-alert margin-top="base" type="danger" @close="redirectToClients">
            <template #content>
                <p>It looks like you do not have the right permissions to see the library for this campaign.</p>
                <p>Ask your admin to grant you access and try again.</p>
            </template>
            <template #actionItems>
                <Button type="primary" @click="redirectToClients">Got it</Button>
            </template>
        </hox-alert>
    </hox-modal>
    <div v-else class="variants-library__wrapper">
        <div>
            <hox-loading-layer v-if="isLoading" is-opaque :left-offset-pixels="60" size="large" />
            <div v-if="hasErrorLoadingRequiredData" class="variants-library__loading-error-message-wrapper">
                <hox-alert margin-bottom="large" type="danger">
                    <template #title>Uh oh!</template>
                    <template #content>
                        <p>
                            There was an unexpected issue while loading library data. Hopefully it was a temporary issue
                            and should be fixed if you try again in a few moments.
                        </p>
                    </template>
                    <template #actionItems>
                        <Button type="primary" @click="getRequiredLibraryData">Retry</Button>
                    </template>
                </hox-alert>
            </div>
            <div v-else-if="!hasFilterOptionsForEachType">
                <hox-empty-message v-if="userCanAccessEditor">
                    <template #title>This campaign doesn't have any ads</template>
                    <template #content>
                        <p>Head over to the editor to get started</p>
                    </template>
                </hox-empty-message>
                <hox-empty-message v-else>
                    <template #title>There are no ads in the library</template>
                </hox-empty-message>
            </div>
            <template v-else>
                <div v-if="isPaginationNoticeShown" class="variants-library__select-all-notice-wrapper">
                    <select-all-notice
                        :all-selected-mode="areAllDeliverablesSelected"
                        :selected="selectedDeliverableIds.length"
                        :total-count="pagination.totalItems"
                        @dismiss="isPaginationNoticeShown = false"
                        @selectAll="onSelectAll"
                    />
                </div>
                <div class="variants-wrap">
                    <div class="select-variants__filters">
                        <div
                            v-if="hasFilterOptionsForEachType"
                            class="campaign-light__sidebar variants-library__sidebar"
                        >
                            <div v-show="filtersTab === 'creatives'">
                                <library-language-filter
                                    :selected-language-filters="value.languages"
                                    @updateSelectedLanguageFilters="onUpdateLanguageFilters"
                                ></library-language-filter>
                                <library-size-filter
                                    :selected-size-filters="value.masterTemplateIds"
                                    @updateSelectedSizeFilters="onUpdateSizeFilters"
                                ></library-size-filter>
                                <editable-group-checkbox-list
                                    :selected-editable-groups-filters="value.editableGroupValueIds"
                                    @updateGroupsFilters="onUpdateGroupsFilters"
                                ></editable-group-checkbox-list>
                            </div>
                        </div>
                    </div>
                    <div class="variants-list">
                        <div ref="body" :class="bodyClasses">
                            <campaign-locked-notice
                                :class="{ 'campaign-locked-notice--table-view': !isGridViewMode }"
                            />
                            <div v-if="!allFiltersAreActive" class="variants-library__empty-message-wrapper">
                                <hox-empty-message>
                                    <template #title>No deliverables to show</template>
                                    <template #content>
                                        <!-- prettier-ignore -->
                                        <p>You need to select at least one <em>language</em>, <em>size</em> and <em>group value for every group filter</em> in the sidebar on the left.</p>
                                    </template>
                                </hox-empty-message>
                            </div>
                            <hox-alert v-else-if="hasErrorLoadingDeliverables" margin-bottom="large" type="danger">
                                <template #title>Uh oh!</template>
                                <template #content>
                                    <p>
                                        There was an unexpected issue while getting the deliverables. Hopefully it was a
                                        temporary issue and should be fixed if you try again in a few moments.
                                    </p>
                                </template>
                                <template #actionItems>
                                    <Button type="primary" @click="getPaginatedDeliverables">
                                        Retry getting deliverables
                                    </Button>
                                </template>
                            </hox-alert>
                            <div
                                v-else-if="!paginatedDeliverables.length && !isLoadingDeliverables"
                                class="variants-library__empty-message-wrapper"
                            >
                                <library-empty-message />
                            </div>
                            <template v-else>
                                <div v-if="isGridViewMode">
                                    <div
                                        v-for="size in Object.keys(deliverableSizeGroups)"
                                        :key="size"
                                        class="campaign-edit__deliverables-size-group"
                                    >
                                        <div
                                            v-for="(deliverable, index) in deliverableSizeGroups[size]"
                                            :key="deliverable.idHash + index"
                                            class="campaign-edit__deliverable"
                                        >
                                            <banner-preview
                                                :banner="previewBannerAdapter(deliverable)"
                                                :lazy-load="true"
                                                :scaling-factor="scalingFactor"
                                                :preview-values="previewValues(deliverable)"
                                                :ad-type="deliverable.masterTemplate.adType"
                                                @BannerLoaded="onBannerLoaded(deliverable)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hox-loading-layer v-if="isLoadingDeliverables" :is-full-screen="false" />
                            </template>
                        </div>
                        <div
                            v-if="paginatedDeliverables.length || hasErrorLoadingDeliverables || isLoadingDeliverables"
                            :key="pagination.totalPages"
                            class="variants-library__footer"
                        >
                            <div class="variants-library__footer-items">
                                <div v-if="isGridViewMode" class="variants-library__scale-options-wrapper">
                                    <p>Ad scaling:</p>
                                    <Select v-model="scalingFactor" @on-change="setScalePageSize">
                                        <Option :value="0.9999">Auto</Option>
                                        <Option :value="0.25">25%</Option>
                                        <Option :value="0.5">50%</Option>
                                        <Option :value="0.75">75%</Option>
                                        <Option :value="1">100%</Option>
                                    </Select>
                                </div>
                                <banner-scrubbar
                                    v-if="isGridViewMode"
                                    class="variants-library__footer-item variants-library__scrubbar"
                                    :value="bannerPlaybackTime"
                                    @change="setBannerPlaybackTime"
                                ></banner-scrubbar>
                                <campaign-banner-play-all
                                    v-if="isGridViewMode"
                                    class="variants-library__footer-item"
                                ></campaign-banner-play-all>
                                <div v-if="!isGridViewMode" class="variants-library__footer-spacer"></div>
                                <Page
                                    class="variants-library__footer-item variants-library__page-select"
                                    :current="pagination.currentPage"
                                    :page-size="pagination.pageSize"
                                    :total="pagination.totalItems"
                                    @on-change="setCurrentPage"
                                />
                                <div
                                    v-if="!isGridViewMode"
                                    class="variants-library__footer-item variants-library__page-size-select-wrapper"
                                >
                                    <Select
                                        :value="pagination.pageSize"
                                        :disabled="isGridViewMode"
                                        @on-change="setPageSize"
                                    >
                                        <Option
                                            v-for="option in pageSizeOptions"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{ option.label }}
                                        </Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { generateTemplateLabel, numberWithCommas } from "@/utils";
import { CampaignAction } from "@/store/modules/campaign";
import {
    DeliverableLibraryAction,
    DeliverableLibraryGetters,
    ViewMode,
    ViewModeDefaultPageSize
} from "@/store/modules/deliverableLibrary";

import Deliverable from "@/services/Deliverables";
import { getMetadataFieldsOfCampaign } from "@/services/Metadata";

import campaignLibraryQuery from "@/apollo/queries/v2/CampaignLibrary.gql";
import computedDeliverableComponentsQuery from "@/apollo/queries/v2/ComputedDeliverableComponents.gql";

import EditableGroupCheckboxList from "@/components/IntelligentDelivery/EditableGroupCheckboxList";
import LibrarySizeFilter from "@/components/IntelligentDelivery/LibrarySizeFilter";
import LibraryLanguageFilter from "@/components/IntelligentDelivery/LibraryLanguageFilter";
import BannerPreview from "@/components/Previews/BannerPreview";
import BannerScrubbar from "@/components/Previews/BannerScrubbar";
import CampaignBannerPlayAll from "@/components/Campaign/CampaignBannerPlayAll";
import deliverableMethodsMixin from "@/mixins/deliverableMethodsMixin";

import { UiAction } from "@/store/modules/ui";
import SelectAllNotice from "@/components/Campaign/SelectAllNotice";
import bannerTimelineMixin from "@/mixins/bannerTimelineMixin";
import LibraryEmptyMessage from "@/components/Campaign/LibraryEmptyMessage";
import scalingBannerWrapperMixin from "@/mixins/scalingBannerWrapperMixin";
import ImportExport from "@/services/ImportExport";
import CampaignLockedNotice from "@/views/Campaign/CampaignLockedNotice";

export default {
    name: "SelectVariants",
    components: {
        BannerPreview,
        CampaignLockedNotice,
        LibraryEmptyMessage,
        SelectAllNotice,
        LibraryLanguageFilter,
        LibrarySizeFilter,
        EditableGroupCheckboxList,
        BannerScrubbar,
        CampaignBannerPlayAll
    },

    mixins: [deliverableMethodsMixin, bannerTimelineMixin, scalingBannerWrapperMixin],

    props: {
        value: {
            type: Object
        }
    },

    data() {
        return {
            areAllDeliverablesSelected: false,
            filtersTab: "creatives",
            scalingFactor: 0.9999,
            getPaginatedDeliverablesTimeout: undefined,
            hasErrorLoadingCampaign: false,
            hasErrorLoadingDeliverables: false,
            hasErrorLoadingFilterOptions: false,
            hasErrorLoadingMetadataFields: false,
            isBeingDestroyed: false,
            isLoadingCampaign: 0,
            isLoadingDeliverables: true,
            loadingLibraryFilterOptions: 0,
            isLoadingMetadataFields: false,
            isPaginationNoticeShown: false,
            mounted: true,
            multiviewInstanceName: "library",
            numberOfDeliverablesThatUserCanView: 0,
            pageSizeOptions: [
                {
                    value: 10,
                    label: "10/page"
                },
                {
                    value: 25,
                    label: "25/page"
                },
                {
                    value: 50,
                    label: "50/page"
                },
                {
                    value: 75,
                    label: "75/page"
                },
                {
                    value: 100,
                    label: "100/page"
                }
            ],
            paginatedDeliverables: [],
            paginatedDeliverablesRequestCounter: 0,
            resizedBanners: [],
            scrubbarSource: null,
            selectedDeliverableIds: [],
            selectedDeliverableIdentifiers: [],
            selectedEditableGroupsFilters: []
        };
    },

    computed: {
        allFiltersAreActive() {
            return (
                this.allCreativeFiltersHaveSelection &&
                this.value.languages.length > 0 &&
                this.value.masterTemplateIds.length > 0
            );
        },

        allCreativeFiltersHaveSelection() {
            const editableGroupsFilterOptions = this.$store.state.deliverableLibrary.filterOptions.editableGroups;

            return editableGroupsFilterOptions.every(obj =>
                obj.options.some(option => this.value.editableGroupValueIds.includes(option.value))
            );
        },

        bannerIframeId() {
            if (this.scrubbarSource) {
                return `iframe-${this.scrubbarSource.idHash}`;
            }

            return null;
        },

        bodyClasses() {
            const classes = ["variants-library__body"];
            if (!this.isGridViewMode && !this.hasErrorLoadingDeliverables) {
                classes.push("variants-library__body--no-padding");
            }
            return classes;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        canPreviewDeliverable() {
            return this.$auth.userCan(this.$auth.Actions.CanPreviewDeliverable, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        deliverableSizeGroups() {
            return this.paginatedDeliverables.reduce((acc, deliverable) => {
                const size = `${deliverable.masterTemplate.width}x${deliverable.masterTemplate.height}`;
                acc[size] = (acc[size] || []).concat(deliverable);

                return acc;
            }, {});
        },

        filterParams() {
            return this.$store.getters[DeliverableLibraryGetters.urlFilterParams];
        },

        formatedGoupValueFilters() {
            const editableGroupsFilterOptions = this.$store.state.deliverableLibrary.filterOptions.editableGroups;

            return editableGroupsFilterOptions
                .filter(group => group.options.some(option => this.value.editableGroupValueIds.includes(option.value)))
                .map(group => {
                    const validOptions = group.options.filter(option =>
                        this.value.editableGroupValueIds.includes(option.value)
                    );
                    const groupValues = validOptions.map(option => option.value);
                    return {
                        groupName: group.name,
                        groupValues,
                        type: "GroupValue"
                    };
                });
        },

        hasErrorLoadingRequiredData() {
            return (
                this.hasErrorLoadingCampaign || this.hasErrorLoadingFilterOptions || this.hasErrorLoadingMetadataFields
            );
        },

        hasFilterOptionsForEachType() {
            const { filterOptions } = this.$store.state.deliverableLibrary;

            return (
                filterOptions.editableGroups.length > 0 &&
                filterOptions.languages.length > 0 &&
                filterOptions.masterTemplates.length > 0
            );
        },

        isGridViewMode() {
            return this.viewMode === ViewMode.Grid;
        },

        isInitialisedForCampaign() {
            return this.libraryCampaignId && this.libraryCampaignId === this.campaignId;
        },

        isLoading() {
            return this.isLoadingCampaign || this.loadingLibraryFilterOptions || this.isLoadingMetadataFields;
        },

        isPermissionGranted() {
            return this.userHasScope && this.canPreviewDeliverable;
        },

        libraryCampaignId: {
            get() {
                return this.$store.state.deliverableLibrary.campaignId;
            },
            set(campaignId) {
                this.$store.dispatch(DeliverableLibraryAction.SetCampaignId, campaignId);
            }
        },

        libraryFiltersForQuery() {
            const groupValueFilters = this.getGroupValuesForQuery();

            return {
                groupValueFilters,
                sizeFilters: this.value.masterTemplateIds,
                languageFilters: this.value.languages,
                visibleToFilters: this.visibleToFilters
            };
        },

        metadataFieldsByFieldId() {
            return this.$store.state.deliverableLibrary.metadataFieldsByFieldId;
        },

        metadataSearchFilters() {
            return this.$store.state.deliverableLibrary.metadataSearchFiltersByFieldId;
        },

        pagination: {
            get() {
                return this.$store.state.deliverableLibrary.pagination;
            },
            set(newPagination) {
                this.$store.dispatch(DeliverableLibraryAction.SetPagination, newPagination);
            }
        },

        selectedDeliverables() {
            return this.paginatedDeliverables.filter(deliverable =>
                this.selectedDeliverableIds.includes(deliverable.idHash)
            );
        },

        sizeFilters() {
            return this.$store.getters[DeliverableLibraryGetters.sizeFilters];
        },

        statusFilter() {
            return this.$store.getters[DeliverableLibraryGetters.statusFilter];
        },

        totalGeneratedVariations() {
            if (this.allFiltersAreActive) {
                return numberWithCommas(this.pagination.totalItems);
            }
            return 0;
        },

        totalVariations() {
            return numberWithCommas(this.numberOfDeliverablesThatUserCanView);
        },

        userCanAccessEditor() {
            return this.$auth.userCan(this.$auth.Actions.CanManageOverwrites, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        userCanChangeDeliverableVisibility() {
            return this.$auth.userCan(this.$auth.Actions.CanChangeDeliverableVisibility, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        userHasScope() {
            return this.$auth.hasScope({
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        userStatusFilter() {
            return this.$store.getters[DeliverableLibraryGetters.userStatusFilter];
        },

        viewMode: {
            get() {
                return this.$store.state.deliverableLibrary.viewMode;
            },
            set(newViewMode) {
                this.$store.dispatch(DeliverableLibraryAction.SetViewMode, newViewMode);
            }
        },

        visibleToFilters() {
            return this.$store.getters[DeliverableLibraryGetters.VisibleToFilter];
        }
    },

    watch: {
        bannerState(state) {
            if (state && state.currentTime) {
                this.setBannerPlaybackTimeFromBanner(state.currentTime);
            }
        },

        value: {
            deep: true,
            handler() {
                this.areAllDeliverablesSelected = true;
                this.getPaginatedDeliverables();
            }
        },

        totalGeneratedVariations() {
            this.$emit("selectedVariantsCount", {
                total: this.totalVariations,
                selected: this.totalGeneratedVariations
            });
        },

        totalVariations() {
            this.$emit("selectedVariantsCount", {
                total: this.totalVariations,
                selected: this.totalGeneratedVariations
            });
        },

        filterParams: {
            deep: true,
            // eslint-disable-next-line complexity
            handler() {
                if (this.hasFilterOptionsForEachType) {
                    /*
                        As this is triggered on initial load of the page by setting
                        filters from URL params it could overwrite the page set by
                        URL params, as a result we guard it with a flag.
                    */
                    if (this.hasInitialisedFilterParams) {
                        this.setCurrentPage(1);
                        /*
                            If we change filters then we don't know how many pages we have
                            so we'll default to 1 while the data is loading.
                        */
                        this.pagination = {
                            ...this.pagination,
                            totalItems: this.pagination.pageSize
                        };
                    }
                    if (this.value.masterTemplateIds.length > 0) {
                        /*
                            The LibrarySizeFilter component currently sets the initial
                            state for the template/size filters, so we're not going
                            to toggle the initialised flag to true until we have size
                            filters.
                        */
                        this.hasInitialisedFilterParams = true;
                    }

                    this.mounted = false;
                    this.getPaginatedDeliverables();
                }
            }
        },

        isGridViewMode() {
            this.paginatedDeliverables = [];
            if (this.isGridViewMode) {
                this.$store.dispatch(DeliverableLibraryAction.ClearMetadataSearchFiltersByFieldId);
            }
        },

        async isLoading() {
            if (!this.isLoading) {
                if (this.hasErrorLoadingRequiredData || !this.hasFilterOptionsForEachType) {
                    this.$store.dispatch(UiAction.CloseSidebar);
                    return;
                }
                if (!this.isInitialisedForCampaign) {
                    this.$store.dispatch(DeliverableLibraryAction.SetInitialState, this.$route.query);
                }
                this.getPaginatedDeliverables();
            }
            await this.$nextTick();
            this.setBodyDimensionsPixels();
        },

        isPermissionGranted() {
            this.getMetadataFields();
        },

        metadataSearchFilters() {
            this.setCurrentPage(1);
            this.pagination = {
                ...this.pagination,
                totalItems: this.pagination.pageSize
            };
            this.getPaginatedDeliverables();
        },

        isLoadingDeliverables(val) {
            this.$emit("loadingDeliverables", val);
        }
    },

    created() {
        window.addEventListener("resize", this.setBodyDimensionsPixels, true);
        this.deliverableService = new Deliverable(this.$apollo, this.$store);
        this.importExportService = new ImportExport(this.$apollo, this.$store);
        this.$store.dispatch(UiAction.SetSidebarWidthOpen, 300);
        if (!this.isInitialisedForCampaign) {
            this.libraryCampaignId = null;
        }
        this.getMetadataFields();
        this.setPageSize(ViewModeDefaultPageSize[ViewMode.Grid]);
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.setBodyDimensionsPixels, true);
        this.isBeingDestroyed = true;
        this.$store.dispatch(DeliverableLibraryAction.UnsetDeliverableStatusByIdHash, this.paginatedDeliverables);
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        hasMoreToSelect(selectedDeliverableIds) {
            return (
                selectedDeliverableIds.length &&
                selectedDeliverableIds.length === this.paginatedDeliverables.length &&
                selectedDeliverableIds.length !== this.pagination.totalItems
            );
        },

        async getMetadataFields() {
            this.hasErrorLoadingMetadataFields = false;
            if (this.isPermissionGranted && !this.isLoadingMetadataFields) {
                this.isLoadingMetadataFields = true;
                try {
                    const metadataFields = await getMetadataFieldsOfCampaign(this.campaignId);
                    this.$store.dispatch(DeliverableLibraryAction.ClearMetadataFieldsByFieldId);
                    metadataFields.forEach(metadataField => {
                        this.$store.dispatch(DeliverableLibraryAction.SetMetadataFieldsByFieldId, metadataField);
                    });
                } catch (err) {
                    this.hasErrorLoadingMetadataFields = true;
                }
                this.isLoadingMetadataFields = false;
            }
        },

        async getPaginatedDeliverables() {
            // eslint-disable-next-line complexity
            const getPaginatedDeliverables = async () => {
                this.hasErrorLoadingDeliverables = false;
                /*
                    While it may change in the future, we want to clear the selected deliverables
                    any time we attempt to fetch new deliverables.
                */
                this.setSelectedDeliverableIds([]);
                /*
                    Because we may have long running requests that may complete after shorter requests
                    we are going to check against a component level counter to ensure that the request
                    is the most recent before setting any state as a result of the query.
                */
                const isMostRecentRequest = requestCount =>
                    this.paginatedDeliverablesRequestCounter === requestCount &&
                    this.getPaginatedDeliverablesTimeout === undefined;
                this.paginatedDeliverablesRequestCounter += 1;
                /*
                    So that `deliverableStatusByIdHash` is not unbounded in
                    how large it can grow we clear out the existing deliverables.
                */
                this.$store.dispatch(
                    DeliverableLibraryAction.UnsetDeliverableStatusByIdHash,
                    this.paginatedDeliverables
                );
                /*
                    It's possible for this function to be called before the page has finished
                    loading things such as editables and masterTemplates, which we require to
                    process the deliverables, so we don't want to allow fetching until they've been
                    loaded.

                    There's also no point in attempting to query without having all filters as the
                    response would contain no deliverables.
                */
                if (!this.isLoading && this.allFiltersAreActive) {
                    const requestCount = this.paginatedDeliverablesRequestCounter;
                    this.isLoadingDeliverables = true;
                    /*
                        We're going to clear the deliverables when we start to fetch new deliverables,
                        partly because it looks cleaner, but mainly because it allows us to avoid issues
                        relating to lazy loaded banners not being correctly initialised.

                        We don't do this for the table view as we want to keep the deliverables so that
                        the table columns do not instantly resize/shift out of view when filtering using
                        the metadata column search inputs.
                    */
                    if (this.isGridViewMode) {
                        this.paginatedDeliverables = [];
                    }
                    this.scrubbarSource = null;
                    this.bannerPlaybackTime = 0;

                    try {
                        const { deliverables, pagination } = await this.deliverableService.getPaginatedDeliverables(
                            this.campaignId,
                            this.libraryFiltersForQuery,
                            this.pagination.currentPage,
                            this.pagination.pageSize
                        );
                        /*
                            If we have a pending request, don't bother proceeding
                            any further as it will be overwritten shortly.
                        */
                        if (!isMostRecentRequest(requestCount)) {
                            return;
                        }
                        /*
                            If somehow the user has managed to get to a page that is beyond the max page
                            we want to bump them down the last possible page to in order correct things.
                        */
                        if (this.pagination.currentPage > pagination.maxPage && pagination.totalCount > 0) {
                            this.setCurrentPage(pagination.maxPage);
                            this.pagination = {
                                ...this.pagination,
                                totalPages: pagination.maxPage,
                                totalItems: pagination.totalCount
                            };
                            return;
                        }
                        this.pagination = {
                            ...this.pagination,
                            totalPages: pagination.maxPage,
                            totalItems: pagination.totalCount
                        };
                        this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverables);
                        this.paginatedDeliverables = deliverables;
                    } catch (err) {
                        if (isMostRecentRequest(requestCount)) {
                            this.hasErrorLoadingDeliverables = true;
                        }
                    } finally {
                        if (isMostRecentRequest(requestCount)) {
                            this.isLoadingDeliverables = false;
                        }
                    }
                } else {
                    this.isLoadingDeliverables = false;
                    this.paginatedDeliverables = [];
                }
            };
            if (this.isLoadingDeliverables) {
                clearTimeout(this.getPaginatedDeliverablesTimeout);
                this.getPaginatedDeliverablesTimeout = setTimeout(() => {
                    this.getPaginatedDeliverablesTimeout = undefined;
                    getPaginatedDeliverables();
                }, 500);
            } else {
                getPaginatedDeliverables();
            }
        },

        getGroupValuesForQuery() {
            return this.formatedGoupValueFilters.map(groupValueFilter => ({
                name: groupValueFilter.groupName,
                valueIds: groupValueFilter.groupValues
            }));
        },

        getRequiredLibraryData() {
            this.getMetadataFields();
            this.$apollo.queries.campaign.refetch();
            this.$apollo.queries.computedDeliverableComponents.refetch();
        },

        onBannerLoaded(deliverable) {
            if (!this.scrubbarSource) {
                this.scrubbarSource = deliverable;
            }
        },

        onResize(deliverable, isResized) {
            if (isResized) {
                this.resizedBanners.push(deliverable._id);
            } else {
                this.resizedBanners = this.resizedBanners.filter(id => deliverable._id !== id);
            }
        },

        onSelectAll() {
            this.setSelectedDeliverableIds(this.paginatedDeliverables.map(({ idHash }) => idHash));
            this.isPaginationNoticeShown = false;
            this.areAllDeliverablesSelected = true;
        },

        onUpdateLanguageFilters(languageFilters) {
            this.$emit("input", { ...this.value, languages: languageFilters });
            this.getPaginatedDeliverables();
        },

        onUpdateSizeFilters(sizeFilters) {
            this.$emit("input", { ...this.value, masterTemplateIds: sizeFilters });
            this.getPaginatedDeliverables();
        },
        onUpdateGroupsFilters(groupFilters) {
            this.$emit("input", { ...this.value, editableGroupValueIds: groupFilters });
            this.getPaginatedDeliverables();
        },

        redirectToClients() {
            this.$router.push({ name: "Home" }).catch(err => {
                if (err.name !== "NavigationDuplicated") {
                    throw err;
                }
            });
        },

        setCurrentPage(newPage) {
            if (newPage !== this.pagination.currentPage) {
                this.pagination = {
                    ...this.pagination,
                    currentPage: newPage
                };
                this.getPaginatedDeliverables();
            }
        },

        setMasterTemplateFilterOptions(masterTemplates) {
            /*
                We do this sort so that the same master templates get suffixed with
                the same number (if there are no changes to the master templates)
                by the generateLabel function if there are multiple master templates
                of the same size.
            */
            const masterTemplatesSortedById = [...masterTemplates].sort((a, b) => {
                if (a.created < b.created) {
                    return -1;
                }
                if (a.created > b.created) {
                    return 1;
                }
                return 0;
            });
            const generatedTemplateLabels = [];
            const masterTemplateOptions = [];
            masterTemplatesSortedById.forEach(masterTemplate => {
                const label = generateTemplateLabel(masterTemplate, generatedTemplateLabels);
                generatedTemplateLabels.push(label);
                masterTemplateOptions.push({
                    height: masterTemplate.width,
                    label,
                    value: masterTemplate._id,
                    width: masterTemplate.width
                });
            });
            /*
                We sort the options by label because it's nicer if the options
                are sorted by dimensions in the UI.
            */
            // eslint-disable-next-line complexity
            const masterTemplateOptionsSortedByDimensions = [...masterTemplateOptions].sort((a, b) => {
                if (a.width < b.width) {
                    return -1;
                }
                if (a.width > b.width) {
                    return 1;
                }
                if (a.height < b.height) {
                    return -1;
                }
                if (a.height > b.height) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
            this.$store.dispatch(
                DeliverableLibraryAction.SetMasterTemplateFilterOptions,
                masterTemplateOptionsSortedByDimensions
            );
        },

        setScalePageSize() {
            switch (this.scalingFactor) {
                case 1:
                    this.setPageSize(10);
                    break;
                case 0.75:
                    this.setPageSize(20);
                    break;
                case 0.5:
                    this.setPageSize(30);
                    break;
                case 0.25:
                    this.setPageSize(40);
                    break;
                default:
                    this.setPageSize(10);
            }
        },

        setPageSize(newPageSize) {
            if (newPageSize !== this.pagination.pageSize) {
                this.pagination = {
                    ...this.pagination,
                    pageSize: newPageSize,
                    /*
                        If we change the page size then we don't know how many pages we have
                        so we'll default to 1 while the data is loading. We do this by setting
                        the total number of items to the new page size.
                    */
                    totalItems: newPageSize
                };
                this.setCurrentPage(1);
                this.getPaginatedDeliverables();
            }
        },

        setSelectedDeliverableIds(selectedDeliverableIds) {
            if (selectedDeliverableIds.length !== this.paginatedDeliverables.length) {
                this.areAllDeliverablesSelected = false;
            }

            if (this.hasMoreToSelect(selectedDeliverableIds)) {
                this.isPaginationNoticeShown = true;
            }

            if (!selectedDeliverableIds.length && this.isPaginationNoticeShown) {
                this.isPaginationNoticeShown = false;
            }

            this.selectedDeliverableIds = selectedDeliverableIds;
        }
    },

    apollo: {
        campaign: {
            query: campaignLibraryQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingCampaign",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            skip() {
                return !this.isPermissionGranted;
            },

            async result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingCampaign = true;
                    return;
                }
                this.hasErrorLoadingCampaign = false;
                this.$store.dispatch(CampaignAction.SetCampaign, data.campaign);
                this.$store.dispatch(CampaignAction.SetMasterTemplates, data.masterTemplatesByCampaign);
                this.$store.dispatch(CampaignAction.SetGroups, data.editableGroupsByCampaign);
                // make sure that the changes made in editor are reflected in filters
                this.$store.dispatch(DeliverableLibraryAction.SyncFilters);
            }
        },
        computedDeliverableComponents: {
            query: computedDeliverableComponentsQuery,
            fetchPolicy: "network-only",
            loadingKey: "loadingLibraryFilterOptions",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            skip() {
                return !this.isPermissionGranted;
            },

            result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingFilterOptions = true;
                    return;
                }
                this.hasErrorLoadingFilterOptions = false;
                const { deliverableCount, languages, masterTemplates } = data.computedDeliverableComponents;
                this.numberOfDeliverablesThatUserCanView = deliverableCount;
                this.$store.dispatch(DeliverableLibraryAction.SetLanguageFilterOptions, languages);
                this.setMasterTemplateFilterOptions(masterTemplates);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.variants-library__wrapper {
    height: 100%;
    position: relative;
    width: 100%;
}

.variants-library {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &__body {
        flex: 1;
        overflow-y: auto;
        padding: $spacing-large 20px $spacing;
        position: relative;

        &--no-padding {
            padding: 0;
        }

        &--no-scroll {
            overflow-y: hidden;
        }
    }

    &__empty-message-wrapper {
        padding: $spacing-large 0 0;
    }

    &__loading-error-message-wrapper {
        padding: 0 $spacing-large;
    }

    &__footer {
        padding: $spacing 0 0;
        border-top: 1px solid $grey3;
        display: flex;

        &-spacer {
            flex: 1 1 100%;
            width: 100%;
        }

        &-item {
            flex: 0 0 auto;
            max-width: 100%;
            padding: 0 0 0 $spacing-smaller;
            width: auto;
        }

        &-items {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: space-between;

            .ivu-select {
                padding: 0;

                .ivu-select-selection {
                    padding: 6px 10px;
                }
            }
        }
    }

    &__scrubbar {
        flex: 1;
        margin-right: 30px;
    }

    .banner-scrubbar {
        flex: 1 0 100px;
        min-width: 100px;

        .ivu-slider-wrap {
            margin: 0 9px;
        }
    }

    .play-pause-all {
        flex: 0 0;
        margin-left: $spacing-large;
    }

    &__page-select {
        flex: 0 0;
        white-space: nowrap;
    }

    &__page-size-select-wrapper {
        flex: 0 0;
        padding: 0 0 0 $spacing;
    }

    &__scale-options-wrapper {
        align-items: center;
        display: flex;
        margin: 0 $spacing-small 0 0;
        white-space: nowrap;

        p {
            margin: 0 $spacing-smaller 0 0;
        }
    }

    &__select-all-notice-wrapper {
        border-bottom: 1px solid $grey3;
        padding: $spacing 20px 0;
    }

    &__variations {
        color: $cmp-light-active;
        font-weight: bold;
        height: $campaign-nav-height;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $grey3;
    }
}

.campaign-edit__deliverables-size-group {
    width: 100%;

    &--single-child {
        width: auto;
        display: inline-block;
    }
}

.select-variants__filters {
    width: 25%;
    overflow: auto;
}

.variants {
    &-wrap {
        max-height: calc(100vh - 162px);
        display: flex;
    }

    &__select-all-wrap {
        padding: 20px;
    }

    &-list {
        margin-left: 5%;
        width: 70%;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        &__header {
            display: flex;
            border-bottom: 1px solid #dcdcdc;
            padding-bottom: 10px;
            align-items: center;
            height: 60px;

            button {
                margin-left: auto;
            }
        }
    }
}
</style>
