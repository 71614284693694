<template>
    <div :style="containerStyle">
        <div v-if="isLoading || !videoSrc" class="ae-banner__loading-spinner-wrapper">
            <hox-loading-spinner v-if="isLoading" :size="40" />
            <Icon v-else type="md-image"></Icon>
        </div>
        <AEVideo v-else :id="id" :src="videoSrc" />
    </div>
</template>

<script>
import equal from "fast-deep-equal";

import { AERenderService, PLAINLY_POLL_INTERVAL } from "@/utils/ae";
import getAfterEffectsApiInfoQuery from "@/apollo/queries/v2/GetAfterEffectsApiInfo.gql";
import { MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";
import { BannersAction } from "@/store/modules/banners.store";

import AEVideo from "./AEVideo";

const DEFAULT_WIDTH = 360;
const DEFAULT_HEIGHT = 240;

/**
 * @typedef {Object} PreviewValues
 * @property {import("shared-utils/types").ComputedOverwrite[]} computedOverwrites
 * @property {Object} masterTemplate
 * @property {string} masterTemplate._id
 * @property {string} masterTemplate.persistentKey
 * @property {string} masterTemplate.width
 * @property {string} masterTemplate.height
 * @property {Object} masterTemplate.aeInfo
 * @property {boolean} masterTemplate.aeInfo.completed
 * @property {string} masterTemplate.aeInfo.plainlyProjectId
 * @property {string} masterTemplate.aeInfo.plainlyTemplateId
 * @property {string} masterTemplate.reportingLabel
 * @property {string} previewUrl
 * @property {string} campaignId
 * @property {Object[]} metadata
 * @property {string} metadata.fieldName
 * @property {string} metadata.value
 * @property {string} [psdThumbnail]
 * @property {string} [reportingLabel]
 */

export default {
    name: "AEBanner",

    components: {
        AEVideo
    },

    props: {
        width: {
            required: true,
            type: [String, Number]
        },
        height: {
            required: true,
            type: [String, Number]
        },
        id: {
            required: true,
            type: String
        },
        previewValues: {
            required: true,
            type: String
        },
        editorValues: {
            default: () => ({}),
            type: Object
        },
        scalingFactor: {
            default: 1,
            type: Number
        },
        isResizable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLoading: true,
            isPending: false,
            /** @type {NodeJS.Timeout|null} */
            renderPendingTimeoutId: null,
            /** @type {string|null} */
            previewRenderUrl: null
        };
    },

    computed: {
        videoSrc() {
            return this.previewRenderUrl;
        },

        containerStyle() {
            return {
                height: `${(this.height || DEFAULT_HEIGHT) * this.scalingFactor}px`,
                width: `${(this.width || DEFAULT_WIDTH) * this.scalingFactor}px`,
                overflow: "hidden"
            };
        },

        /**
         * @returns {PreviewValues}
         */
        data() {
            return JSON.parse(this.previewValues);
        },

        /**
         * @returns {import("shared-utils/types").ComputedOverwrite[]}
         */
        liveComputedOverwrites() {
            return this.data.computedOverwrites.map(overwrite => {
                const newValue = this.editorValues[overwrite.name] || overwrite.newValue;

                return {
                    ...overwrite,
                    newValue
                };
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        AERenderService() {
            return AERenderService({
                clientId: this.clientId,
                aeApiInfo: {
                    baseUrl: this.afterEffectsApiInfo.url,
                    apiKey: this.afterEffectsApiInfo.apiKey
                }
            });
        }
    },

    watch: {
        afterEffectsApiInfo(newValue, oldValue) {
            if (equal(newValue, oldValue)) {
                return;
            }

            this.requestAEPreview();
        },

        editorValues(newValue, oldValue) {
            if (equal(newValue, oldValue)) {
                return;
            }

            this.requestAEPreview();
        },

        data(newValue, oldValue) {
            if (equal(newValue, oldValue)) {
                return;
            }

            if (
                newValue?.masterTemplate?.id !== oldValue?.masterTemplate?.id ||
                (!oldValue?.masterTemplate?.aeInfo?.completed && newValue?.masterTemplate?.aeInfo?.completed)
            ) {
                this.requestAEPreview();
            }
        }
    },

    created() {
        this.$store.dispatch(BannersAction.Init, this.id);
    },

    beforeDestroy() {
        if (this.renderPendingTimeoutId) {
            clearTimeout(this.renderPendingTimeoutId);
        }

        this.AERenderService.clear();
    },

    apollo: {
        afterEffectsApiInfo: {
            query: getAfterEffectsApiInfoQuery,
            result({ data }) {
                this.$store.dispatch(MasterTemplateLibraryAction.SetAfterEffectsApiInfo, data.afterEffectsApiInfo);
            }
        }
    },

    methods: {
        async requestAEPreview() {
            if (this.renderPendingTimeoutId) {
                clearTimeout(this.renderPendingTimeoutId);
            }

            const {
                masterTemplate: { aeInfo, reportingLabel: masterTemplateReportingLabel },
                reportingLabel
            } = this.data;

            if (!aeInfo.completed) {
                return;
            }

            this.renderPendingTimeoutId = setTimeout(async () => {
                this.isLoading = true;

                try {
                    const requestId = this.renderPendingTimeoutId;

                    const url = await this.AERenderService.requestRender({
                        reportingLabel: reportingLabel || masterTemplateReportingLabel,
                        computedOverwrites: this.liveComputedOverwrites,
                        aeInfo,
                        preview: true
                    });

                    if (requestId !== this.renderPendingTimeoutId) {
                        return;
                    }

                    this.previewRenderUrl = url;
                    this.isLoading = false;
                } catch (error) {
                    this.isLoading = false;

                    if (typeof error === "string") {
                        this.$snackbar.error(error);
                    } else if (error instanceof Error) {
                        this.$snackbar.error(error.message);
                    }
                }
            }, PLAINLY_POLL_INTERVAL);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.ae-banner__loading-spinner-wrapper {
    color: $cmp-light-font-color;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}
</style>
