<template>
    <div class="intelligent-delivery__header-wrapper">
        <div class="intelligent-delivery__header-action-wrapper left">
            <Checkbox
                v-model="allFiltersSelected"
                data-testid="select-all-variants__checkbox"
                @input="onSelectAllFilters"
            >
                Select all
            </Checkbox>
            <div class="intelligent-delivery__header-variants-counter">
                Selecting
                <hox-loading-spinner v-if="isLoadingDeliverables" />
                <template v-else>{{ selectedVariantsCount.selected }}</template>
                of {{ selectedVariantsCount.total }} variations
            </div>
        </div>
        <div class="intelligent-delivery__header-action-wrapper right">
            <div class="save-changes">
                <Button
                    class="intelligent-delivery__header-action-btn"
                    data-testid="editor-header__go-back-button"
                    @click="closeVariantsSelection"
                >
                    Go Back
                </Button>
                <Button
                    type="primary"
                    class="intelligent-delivery__header-action-btn"
                    data-testid="editor-header__save-variants-button"
                    :disabled="selectedVariantsCount.selected === 0"
                    @click="saveVariantsSelection"
                >
                    Save
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderActionsVariantsSelection",

    props: {
        selectedVariantsCount: {
            type: Object
        },
        isLoadingDeliverables: {
            type: Boolean
        }
    },

    data() {
        return {
            allFiltersSelected: this.selectedVariantsCount.selected === this.selectedVariantsCount.total
        };
    },

    watch: {
        selectedVariantsCount: {
            handler(newVal) {
                this.allFiltersSelected = newVal.selected === newVal.total;
            },
            deep: true
        }
    },

    methods: {
        saveVariantsSelection() {
            this.$emit("saveVariantsSelection");
        },

        closeVariantsSelection() {
            this.$emit("closeVariantsSelection");
        },

        onSelectAllFilters(value) {
            this.$emit("selectAllFilters", value);
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.intelligent-delivery__header {
    &-variants-counter {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid #c1c7cd;
        display: flex;
        align-items: center;

        .loading-spinner {
            margin: 0 5px;
        }
    }
}
</style>
