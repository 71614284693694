<template>
    <div>
        <Dropdown
            placement="bottom-start"
            trigger="custom"
            :visible="dropdownIsVisible"
            @on-clickoutside="toggleDropdown"
        >
            <hox-dropdown-button
                :is-active="dropdownIsVisible"
                :is-disabled="isDisabled"
                :is-loading="isInProgress"
                data-testid="library__publish-status-select"
                @click="toggleDropdown"
            >
                <slot>Publish</slot>
            </hox-dropdown-button>
            <template #list>
                <DropdownItem
                    data-testid="library-doubleclick--publish"
                    @click.native="setPublishedStatus(Platform.DoubleClick, true)"
                >
                    Publish to {{ PlatformLabel[Platform.DoubleClick] }} feed
                </DropdownItem>
                <DropdownItem
                    data-testid="library-doubleclick--unpublish"
                    @click.native="setPublishedStatus(Platform.DoubleClick, false)"
                >
                    Unpublish from {{ PlatformLabel[Platform.DoubleClick] }} feed
                </DropdownItem>
                <DropdownItem
                    data-testid="library-doubleclick--rebuild"
                    @click.native="rebuild(Platform.DoubleClick)"
                >
                    Rebuild {{ PlatformLabel[Platform.DoubleClick] }} feed
                </DropdownItem>
                <DropdownItem
                    divided
                    data-testid="library-sizmek--publish"
                    @click.native="setPublishedStatus(Platform.Sizmek, true)"
                >
                    Publish to {{ PlatformLabel[Platform.Sizmek] }} feed
                </DropdownItem>
                <DropdownItem
                    data-testid="library-sizmek--unpublish"
                    @click.native="setPublishedStatus(Platform.Sizmek, false)"
                >
                    Unpublish from {{ PlatformLabel[Platform.Sizmek] }} feed
                </DropdownItem>
                <DropdownItem data-testid="library-sizmek--rebuild" @click.native="rebuild(Platform.Sizmek)">
                    Rebuild {{ PlatformLabel[Platform.Sizmek] }} feed
                </DropdownItem>
                <DropdownItem
                    divided
                    data-testid="library-opendc--publish"
                    @click.native="setPublishedStatus(Platform.OpenDC, true)"
                >
                    Publish to
                    {{ isProductionStudioDemo ? "Open Media Studio" : `${PlatformLabel[Platform.OpenDC]} feed` }}
                </DropdownItem>
                <DropdownItem
                    data-testid="library-opendc--unpublish"
                    @click.native="setPublishedStatus(Platform.OpenDC, false)"
                >
                    Unpublish
                    {{ isProductionStudioDemo ? "Open Media Studio" : `${PlatformLabel[Platform.OpenDC]} feed` }}
                </DropdownItem>
                <DropdownItem data-testid="library-opendc--rebuild" @click.native="rebuild(Platform.OpenDC)">
                    Rebuild
                    {{ isProductionStudioDemo ? "Open Media Studio" : `${PlatformLabel[Platform.OpenDC]} feed` }}
                </DropdownItem>
                <DropdownItem
                    v-if="userCanPublishToTikTok"
                    divided
                    data-testid="library-tiktok--publish"
                    @click.native="publishToTikTok"
                >
                    Publish to {{ PlatformLabel[Platform.TikTok] }}
                </DropdownItem>

                <DropdownItem
                    v-if="userCanPublishToFacebook"
                    data-testid="library-facebook--publish"
                    @click.native="showPublishModal(Platform.Facebook, 'publish')"
                >
                    Publish to {{ PlatformLabel[Platform.Facebook] }}
                </DropdownItem>
            </template>
        </Dropdown>
        <div v-if="rebuildFeedModal">
            <alert-modal
                v-model="rebuildFeedModal"
                :header="'Rebuild feed'"
                :title="'Are you sure?'"
                :content="'Rebuilding the feed will unpublish existing deliverables matching the sizes of the current selection. The current selection will then be published to build a new feed for each size selected.'"
                :ok-text="'Rebuild'"
                :on-cancel="clearRebuildFeedCallback"
                :on-ok="rebuildFeedCallback"
            />
        </div>
    </div>
</template>

<script>
import AlertModal from "@/components/Modal/Alert";
import HoxDropdownButton from "@/components/common/DropdownButton";
import { NotificationStatus, NotificationTypes } from "@/enums/notifications";
import { Platform, PlatformLabel, BatchDownloads } from "@/enums/platforms";
import DeliverablesService from "@/services/Deliverables";
import { NotificationsAction } from "@/store/modules/notifications";
import { JobStatusToNotificationStatus } from "@/enums/jobs";
import { JobsAction } from "@/store/modules/jobs";
import { AuthGetters } from "@/store/modules/auth";

export default {
    components: {
        AlertModal,
        HoxDropdownButton
    },

    props: {
        deliverables: {
            required: true,
            type: Array
        },

        isDisabled: {
            required: true,
            type: Boolean
        },

        libraryFiltersForQuery: {
            required: false,
            type: Object
        },

        itemToChangeNumber: {
            required: false,
            type: Number
        }
    },

    data() {
        return {
            dropdownIsVisible: false,
            isSettingPublishedStatus: false,
            rebuildFeedModal: false,
            rebuildFeedCallback: null
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        deliverablesCount() {
            return this.itemToChangeNumber || this.deliverables.length;
        },

        editableGroupValues() {
            return this.$store.state.campaign.editableGroupValues;
        },

        isCampaignLocked() {
            return this.$store.state.campaign.isLocked;
        },

        isProductionStudioDemo() {
            return process.env.VUE_APP_PRODUCTION_STUDIO_DEMO === "true";
        },

        isInProgress() {
            return this.isSettingPublishedStatus || this.isCampaignLocked;
        },

        isSuperAdmin() {
            return this.$store.getters[AuthGetters.isSuperAdmin];
        },

        userCanPublishToFacebook() {
            const { fbAdsManagerConfig } = this.$store.state.campaign.client;
            return fbAdsManagerConfig && this.isSuperAdmin;
        },

        userCanPublishToTikTok() {
            return this.isSuperAdmin;
        }
    },

    created() {
        this.Platform = Platform;
        this.PlatformLabel = PlatformLabel;
        this.deliverablesService = new DeliverablesService(this.$apollo);
    },

    methods: {
        clearRebuildFeedCallback() {
            this.rebuildFeedModal = false;
            this.rebuildFeedCallback = null;
        },

        /**
         * @param {"publish" | "unpublish" | "rebuild"} action
         */
        showPublishModal(platform, action) {
            this.$emit("showPublishModal", {
                platform,
                action
            });
            this.toggleDropdown();
        },

        rebuild(platform) {
            this.rebuildFeedModal = true;
            this.rebuildFeedCallback = () => {
                this.setPublishedStatus(platform, true, true);
                this.clearRebuildFeedCallback();
            };
        },

        // eslint-disable-next-line complexity
        async setPublishedStatus(platform, isPublishing, rebuild) {
            const adsText = `${this.deliverablesCount} ${this.deliverablesCount === 1 ? "ad" : "ads"}`;
            this.isSettingPublishedStatus = true;
            const addingOrRemoving = isPublishing ? "Adding" : "Removing";
            const toOrFrom = isPublishing ? "to" : "from";
            this.$snackbar.info(`${addingOrRemoving} ${adsText} ${toOrFrom} ${this.PlatformLabel[platform]} feeds`);
            this.toggleDropdown();
            let job = null;
            try {
                const args = [this.campaignId, platform, this.deliverables, this.libraryFiltersForQuery];
                if (isPublishing) {
                    job = await this.deliverablesService.publish(...args, rebuild);
                } else {
                    job = await this.deliverablesService.unpublish(...args);
                }
            } catch (err) {
                this.$snackbar.error(err.message);
                return;
            } finally {
                this.isSettingPublishedStatus = false;
            }

            const notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                deliverablesCount: this.deliverablesCount,
                platform,
                status: NotificationStatus.InProgress,
                type: isPublishing ? NotificationTypes.Publish : NotificationTypes.Unpublish,
                job
            });
            this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, {
                jobId: job._id,
                notificationId
            });
            this.$emit("publishedStatusHasBeenSet");
        },

        async publishToTikTok() {
            try {
                const job = await this.deliverablesService.downloadJob(
                    this.campaignId,
                    BatchDownloads.TikTok,
                    this.deliverables,
                    [],
                    this.libraryFiltersForQuery
                );
                const notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                    message: job.message,
                    status: JobStatusToNotificationStatus[job.status],
                    type: NotificationTypes.DownloadJob,
                    job
                });
                this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, {
                    jobId: job._id,
                    notificationId
                });
                this.$snackbar.info(
                    "Your ads are being prepared for publish to TikTok Ads Manager. Take a look at your notifications to check on progress"
                );
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error while preparing your ads for publish to TikTok Ads Manager. Please try again in a few moments"
                );
            }
        },

        toggleDropdown() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
