import { format as dateformat } from "date-fns/format";
// eslint-disable-next-line import/no-extraneous-dependencies
import getObjectValue from "shared-utils/getObjectValue";
// eslint-disable-next-line import/no-extraneous-dependencies
import { getProvider as sharedGetProvider } from "shared-utils/identityProviders";
import snackbar from "@/utils/snackbar";
import languages from "@/utils/languages";

const DATE_FORMAT = "dd/MM/yyyy HH:mm";

const camelToUpperCase = string =>
    string
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .replace(/([a-z])([0-9])/, "$1 $2")
        .replace(/^./, name => name.toUpperCase());

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const formatEditableName = editableName => camelToUpperCase(editableName);

const formatGroupName = groupName => groupName.replace(/(?:^|\s)\S/g, name => name.toUpperCase());

/**
 * @param {number} value
 * @returns {string}
 */
const formatTimestamp = value => (value ? dateformat(value * 1000, DATE_FORMAT) : `${value}`);

const bytesToSize = bytes => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
        return "n/a";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) {
        return `${bytes} ${sizes[i]}`;
    }
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const addEmbedCode = (embedCode, app) => {
    if (!embedCode) {
        return;
    }
    // Create script element if found
    if (embedCode.script) {
        const script = document.createElement("script");
        Object.keys(embedCode.script).forEach(attribute => script.setAttribute(attribute, embedCode.script[attribute]));
        document.getElementsByTagName("head")[0].appendChild(script);
    }
    // Add html to body if found
    if (embedCode.html) {
        document.getElementsByTagName("body")[0].insertAdjacentHTML("beforeend", embedCode.html);
    }
    // Run callback function if found
    if (typeof embedCode.callback === "function") {
        embedCode.callback(app);
    }
};

const deepClone = data => JSON.parse(JSON.stringify(data));

const dedup = (data, key) => Object.values(data.reduce((acc, cur) => Object.assign(acc, { [cur[key]]: cur }), {}));

const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
};

const numberWithCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const splitFileNameAndExtension = fileName => {
    const lastDotIndex = fileName.lastIndexOf(".");
    return {
        extension: lastDotIndex !== -1 ? fileName.substr(lastDotIndex, fileName.length) : "",
        name: fileName.substr(0, lastDotIndex) || fileName
    };
};

/*
    This makes a massive assumption that the file name is the last part of the URL.
*/
const getFileNameFromUrl = url => {
    try {
        const urlParts = new URL(url).pathname.split("/");
        let fileName = urlParts.pop();
        if (fileName === "0") {
            fileName = urlParts[urlParts.length - 1];
        }
        if (!fileName) {
            return url;
        }
        return fileName;
    } catch (err) {
        return url;
    }
};

const getImageDimensionsFromUrl = url =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                height: img.naturalHeight,
                width: img.naturalWidth
            });
        };
        img.onerror = err => reject(err);
        img.src = url;
    });

const constructLibraryFiltersQueryVariables = libraryFilters => {
    const {
        groupValueFilters,
        qaStatusFilters,
        qaUserStatusFilters,
        qaCommentedOnByFilters,
        publishedStatusFilters,
        searchFilters,
        sizeFilters,
        languageFilters,
        visibleToFilters,
        dedup: dedupFilters
    } = libraryFilters;
    const libraryFiltersVars = {
        filters: {
            editableGroups: groupValueFilters,
            masterTemplateIds: sizeFilters,
            languages: languageFilters
        },
        search: searchFilters,
        status: qaStatusFilters,
        userStatus: qaUserStatusFilters,
        publishedStatus: publishedStatusFilters,
        commentedOnBy: qaCommentedOnByFilters,
        ...(dedupFilters && { dedup: dedupFilters })
    };

    if (visibleToFilters && visibleToFilters.length) {
        libraryFiltersVars.visibleTo = visibleToFilters;
    }

    return libraryFiltersVars;
};

const isEmptyObject = obj => Object.getOwnPropertyNames(obj).length === 0;

const debounce = (func, wait, immediate) => {
    let timeout;
    return function debounced(...args) {
        const context = this;
        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };

        if (immediate && !timeout) {
            func.apply(context, args);
        }

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const generateTemplateLabel = ({ height, width }, usedValues) => {
    let value = `${width}x${height}`;
    let i = 0;

    while (usedValues.includes(value)) {
        if (value.indexOf(`_${i}`) > -1) {
            value = value.replace(`_${i}`, `_${(i += 1)}`);
        } else {
            value = `${value}_${(i += 1)}`;
        }
    }

    return value;
};

const sanitizeHTML = str => {
    const temp = document.createElement("div");
    temp.textContent = str;
    return temp.innerHTML;
};

const groupErrorsByField = (errors = []) => {
    const errorsByField = {};
    errors.forEach(err => {
        if (err.extensions && err.extensions.field) {
            const { field } = err.extensions;
            if (!errorsByField[field]) {
                errorsByField[field] = [];
            }
            errorsByField[field].push(err.message);
        }
    });
    return errorsByField;
};

const generateMasterTemplateFilterOptions = masterTemplates => {
    /*
        We do this sort so that the same master templates get suffixed with
        the same number (if there are no changes to the master templates)
        by the generateLabel function if there are multiple master templates
        of the same size.
    */
    const masterTemplatesSortedById = [...masterTemplates].sort((a, b) => a.created < b.created);
    const generatedTemplateLabels = [];
    const masterTemplateOptions = [];
    masterTemplatesSortedById.forEach(masterTemplate => {
        const label = generateTemplateLabel(masterTemplate, generatedTemplateLabels);
        generatedTemplateLabels.push(label);
        masterTemplateOptions.push({
            height: masterTemplate.width,
            label,
            value: masterTemplate._id,
            width: masterTemplate.width
        });
    });
    /*
        We sort the options by label because it's nicer if the options
        are sorted by dimensions in the UI.
    */
    const masterTemplateOptionsSortedByDimensions = [...masterTemplateOptions].sort((a, b) => {
        if (a.width - b.width !== 0) {
            return a.width - b.width;
        }
        if (a.height - b.height !== 0) {
            return a.height - b.height;
        }
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });
    return masterTemplateOptionsSortedByDimensions;
};

// I'm sorry ;(
const isIe = () => /Trident/.test(navigator.userAgent);

/**
 * Check if an upload config has expired or not
 * @param {Object} uploadConfig
 * @returns {Boolean} whether or not the provided uploadConfig has expired
 */
const uploadConfigExpired = uploadConfig => {
    if (!uploadConfig) {
        return false;
    }
    try {
        // Check if the one we have is expired or not
        const { postData: postDataRaw } = uploadConfig;
        const postData = JSON.parse(postDataRaw);
        if (!postData || !postData.Policy) {
            return true;
        }
        const policy = JSON.parse(atob(postData.Policy));
        if (!policy.expiration) {
            return true;
        }
        // Subtracting the current time from the expiry time gives us the number of ms until the
        //  token expires. If that time is longer than 300s (5 mins) then we request a new token
        const expireDate = new Date(policy.expiration);
        const timeToExpireInSeconds = (expireDate.getTime() - Date.now()) / 1000;
        if (timeToExpireInSeconds < 300) {
            return true;
        }
    } catch (err) {
        if (console) {
            console.error(err);
        }
    }

    return false;
};

const kebabCase = string =>
    string
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/\s+/g, "-")
        .toLowerCase();

const numberToShortKM = (v, mprecision = 0, kprecision = 0) => {
    let value = v;
    let suffix = "";
    if (value >= 1000000000) {
        value = Number.parseFloat(value / 1000000000)
            .toFixed(mprecision)
            .replace("000000000", "");
        suffix = "B";
    }

    if (value >= 1000000) {
        value = Number.parseFloat(value / 1000000)
            .toFixed(mprecision)
            .replace("000000", "");
        suffix = "M";
    }

    if (value >= 1000) {
        value = Number.parseFloat(value / 1000)
            .toFixed(kprecision)
            .replace("000", "");
        suffix = "K";
    }

    return `${value}${suffix}`;
};

const objectIsEmpty = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

const isValidHttpUrl = string => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

const getDomain = () => {
    const localDomain = localStorage.getItem("hoxDomain");

    if (localDomain) {
        return localDomain;
    }

    const { hostname } = window.location;

    if (hostname.startsWith("analytics")) {
        return "analytics";
    }

    return "other";
};

const getProvider = email => {
    // we want to disable standard SSO behaviour for those domains
    if (["analytics", "imagine"].includes(getDomain())) {
        return undefined;
    }

    return sharedGetProvider(email, document.subdomain !== "www.hoxton.co");
};
const matchLine = (lines, matchPrefix) => {
    const line = lines.find(l => l.trim().startsWith(matchPrefix)) || "";
    return line.trim().replace(matchPrefix, "");
};

const checkEmptySpaces = value => /^[^\s]/.test(value);
const checkOnlyNumbers = value => /^\d+$/.test(value);

export {
    addEmbedCode,
    bytesToSize,
    camelToUpperCase,
    capitalize,
    checkEmptySpaces,
    checkOnlyNumbers,
    constructLibraryFiltersQueryVariables,
    debounce,
    dedup,
    deepClone,
    formatEditableName,
    formatGroupName,
    formatTimestamp,
    generateMasterTemplateFilterOptions,
    generateTemplateLabel,
    getDomain,
    getFileNameFromUrl,
    getImageDimensionsFromUrl,
    getObjectValue,
    getProvider,
    groupErrorsByField,
    isEmptyObject,
    isIe,
    isValidHttpUrl,
    kebabCase,
    languages,
    numberToShortKM,
    numberWithCommas,
    objectIsEmpty,
    sanitizeHTML,
    snackbar,
    sortByName,
    splitFileNameAndExtension,
    uploadConfigExpired,
    matchLine
};
