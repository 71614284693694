const LP_PROVIDER = "Okta-OIDC-IC-LandingPage";

const lpProviders = {
    [LP_PROVIDER]: [
        "hogarthww.com",
        "hogarth.com",
        "mindshareworld.com",
        "wpp.com",
        "wppcoretech.com",
        "jwt.com",
        "phdmedia.com",
        "annalect.com",
        "gtb.com"
    ]
};

const providers = {
    Hogarth: ["hogarthww.com", "hogarth.com"],
    Mindshare: ["mindshareworld.com"],
    WPP: ["wpp.com"],
    WPPCoretech: ["wppcoretech.com"],
    Wunderman: ["jwt.com"],
    ACME: ["mailinator.com"],
    OMG: ["phdmedia.com", "annalect.com"],
    GTB: ["gtb.com"]
};

const providerKeys = Object.keys(providers);
const lpProviderKeys = Object.keys(lpProviders);

/**
 *
 * @param {string} email
 * @returns {string | undefined}
 */
export const getProvider = (email, lpProvider) => {
    // we want to disable standard SSO behaviour for those stacks
    // nosso stack should be used for testing any changes to SSO configs
    if (["analytics", "imagine", "nosso"].includes(process?.env?.CLIENT_NAME)) {
        return undefined;
    }

    if (process.env.VUE_APP_PRODUCTION_STUDIO_DEMO === "true") {
        return undefined;
    }

    const domain = email.split("@").pop();

    if (lpProvider) {
        return lpProviderKeys.find(p => lpProviders[p].includes(domain));
    }

    return providerKeys.find(p => providers[p].includes(domain));
};

export default providers;
