<template>
    <div class="intelligent-delivery__header-wrapper">
        <div class="intelligent-delivery__header-action-wrapper left">
            <Button
                class="intelligent-delivery__header-action-btn"
                data-testid="editor-header__add-language-button"
                @click="addPlatformAccount"
            >
                Add Meta Account
            </Button>
            <labelled-switch v-model="showDeletedAccounts" type="info">
                <div class="notification-label">Show deleted accounts and rules</div>
            </labelled-switch>
        </div>
        <div v-if="showSaveChanges" class="intelligent-delivery__header-action-wrapper right">
            <div class="save-changes">
                <Button
                    v-if="showSaveResetButtons"
                    class="intelligent-delivery__header-action-btn"
                    data-testid="editor-header__cancel-button"
                    @click="openCancelChangesModal"
                >
                    Reset
                </Button>
                <Button
                    v-if="showSaveResetButtons"
                    class="intelligent-delivery__header-action-btn"
                    data-testid="editor-header__save-button"
                    :disabled="isLoading"
                    @click="openSaveChangesModal"
                >
                    Save
                </Button>
                <Button
                    class="intelligent-delivery__header-action-btn--primary"
                    type="primary"
                    data-testid="editor-header__create-button"
                    :disabled="isLoading"
                    @click="openPublishChangesModal"
                >
                    Publish all
                </Button>
            </div>
        </div>
        <alert-modal
            v-model="cancelChangesModal"
            header="Reset changes"
            title="Are you sure?"
            content="Unsaved changes will be lost. This cannot be undone."
            ok-text="Reset"
            :on-ok="cancelChanges"
        />
        <alert-modal
            v-model="saveChangesModal"
            header="Save changes"
            title="Are you sure?"
            content="You will save the changes made during the current session."
            ok-text="Save"
            :warning="true"
            :on-ok="saveChanges"
        />
        <alert-modal
            v-model="publishChangesModal"
            header="Publish changes"
            title="Are you sure?"
            content="Your unsaved changes will be saved and published."
            ok-text="Publish"
            :warning="true"
            :on-ok="publishChanges"
        />
    </div>
</template>

<script>
import AlertModal from "@/components/Modal/Alert";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";

export default {
    name: "HeaderActionsAccounts",
    components: { AlertModal, LabelledSwitch },

    props: {
        showSaveChanges: {
            type: Boolean,
            default: true
        },
        showSaveResetButtons: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            cancelChangesModal: false,
            saveChangesModal: false,
            publishChangesModal: false,
            showDeletedAccounts: false
        };
    },

    watch: {
        showDeletedAccounts() {
            this.$emit("changeShowDeletedAccounts", this.showDeletedAccounts);
        }
    },

    methods: {
        openCancelChangesModal() {
            this.cancelChangesModal = true;
        },

        openPublishChangesModal() {
            this.publishChangesModal = true;
        },

        openSaveChangesModal() {
            this.saveChangesModal = true;
        },

        cancelChanges() {
            this.$emit("cancelChanges", true);
        },

        saveChanges() {
            this.$emit("saveChanges", true);
        },

        publishChanges() {
            this.$emit("saveChanges");
        },

        addPlatformAccount() {
            this.$emit("showPlatformAccountFormModal");
        }
    }
};
</script>
